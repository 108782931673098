import { CSSProperties } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import styles from './Divider.module.scss';

type DividerProps = {
	alignment?: 'vertical' | 'horizontal';
	color?: string;
	style?: Partial<CSSProperties>;
	/** Vertical or horizontal margin around the divider */
	margin?: CSSProperties['margin'];
};

const Divider = ({ alignment = 'vertical', color = variables.gray2, margin = '1.6rem', style, ...rest }: DividerProps) => {
	const classes = cn(styles['divider'], {
		[styles['divider--horizontal']]: alignment === 'horizontal',
	});
	const horizontalOrVerticalMargin = alignment === 'horizontal' ? `${margin} auto` : `auto ${margin}`;

	return <div className={classes} style={{ backgroundColor: color, margin: horizontalOrVerticalMargin, ...style }} {...rest} />;
};

export default Divider;
