import { generateMetafieldQueries, variantMetafieldIdentifiers } from '../generate-metafield-queries';

const productVariantFragment = /* GraphQL */ `
	fragment productVariantFragment on ProductVariant {
		availableForSale
		id
		image {
			id
			url
			height
			width
			altText
		}
		price {
			amount
			currencyCode
		}
		compareAtPrice {
			amount
			currencyCode
		}
		product {
			... on Product {
				availableForSale
				handle
				id
				productType
				title
			}
		}
		selectedOptions {
			name
			value
		}
		sku
		title
		${generateMetafieldQueries(variantMetafieldIdentifiers)}
	}
`;

export { productVariantFragment };
