// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { LOCALE_CODES, LOCALE_DICT, REFLECTIVE_ADDON_PRICE, FRAME_COLORS } from '@constants';
import { Caption, Dash, Flex, Label, Paragraph } from '@components';
import { formatCurrency } from '@utils/shopify';
import { useWindowDimensions } from '@utils/hooks';
import Swatch from '../Swatch/Swatch';
import styles from './SwatchController.module.scss';

type frameSwatchesProps = {
	handle: string;
	name: string;
};
interface SwatchControllerProps {
	activateOnHover?: boolean;
	alignment?: 'left' | 'center' | 'right';
	callback: ({ option, type }: { option: string; type: 'frame' | 'lens' | 'option' }) => void;
	extraClasses?: string;
	name: string;
	options: readonly string[];
	ref?: ForwardedRef<HTMLDivElement>;
	selected: string;
	title?: string;
	showDescription?: boolean;
	parent?: 'sidebar' | 'product-card';
	type: 'frame' | 'lens' | 'option' | 'accessory';
	frameSwatches?: {
		[key: string]: frameSwatchesProps;
	};
}

const SwatchController: FC<SwatchControllerProps> = forwardRef(
	(
		{
			activateOnHover,
			alignment,
			callback,
			extraClasses,
			name,
			options,
			selected,
			showDescription = false,
			title,
			parent = 'sidebar',
			type,
			frameSwatches,
			...rest
		},
		ref
	) => {
		const { locale } = useRouter();

		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		const reflectivePricing =
			type === 'lens' &&
			selected?.toLowerCase().includes('reflective') &&
			`+${formatCurrency(
				{ amount: REFLECTIVE_ADDON_PRICE, currencyCode: LOCALE_DICT[locale].currencyCode, locale },
				showCurr
			)}`;
		const containerClasses = cn(styles['container'], extraClasses, {
			[styles['container--center']]: alignment === 'center',
			[styles['container--left']]: alignment === 'left',
			[styles['container--right']]: alignment === 'right',
		});

		let baseFrameColorLabel;
		if (selected === FRAME_COLORS.MIX_CRYSTAL_SILVER) {
			baseFrameColorLabel = FRAME_COLORS.CRYSTAL_CLEAR;
		} else if (selected === FRAME_COLORS.MIX_BLACK_GOLD) {
			baseFrameColorLabel = FRAME_COLORS.BLACK;
		} else {
			baseFrameColorLabel = selected;
		}

		const { width } = useWindowDimensions();
		const isDesktopView = width > 991;

		return (
			<div className={containerClasses} ref={ref} {...rest}>
				{parent === 'sidebar' && (
					<Flex center style={{ marginBottom: type === 'option' ? '1.6rem' : '0.8rem' }}>
						<Label>{title}</Label>
						{type !== 'option' && (
							<>
								<Dash />
								<Paragraph>
									{baseFrameColorLabel} {reflectivePricing}
								</Paragraph>
							</>
						)}
					</Flex>
				)}

				{parent === 'product-card' && (
					<Flex center style={{ marginBottom: title && '0.8rem' }}>
						{title && (
							<Paragraph>
								{`${title} ${selected}`} {type === 'lens' && 'Lenses'} {reflectivePricing}
							</Paragraph>
						)}
					</Flex>
				)}

				<Flex className={styles.swatchContainer}>
					<ul
						className={styles['list']}
						style={
							type === 'accessory' || (type === 'option' && isDesktopView)
								? { flexWrap: 'wrap' }
								: { flexWrap: 'none' }
						}
					>
						{options.map((option, index) => (
							<Swatch
								key={`swatch-${option}`}
								activateOnHover={activateOnHover}
								option={option}
								callback={callback}
								index={index}
								name={name}
								selected={selected}
								type={type}
								frameSwatches={frameSwatches}
							/>
						))}
					</ul>
					{showDescription && <Caption className={styles.description}>{`in ${selected}`}</Caption>}
				</Flex>
			</div>
		);
	}
);

SwatchController.displayName = 'SwatchController';

export default SwatchController;
