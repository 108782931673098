import { useRouter } from 'next/router';
import { SwatchController } from '@components';
import { FRAME_COLORS, LENS_COLORS, PRODUCT_TYPES } from '@constants';
import { BaseFrameState } from '@utils/hooks/useBaseFrame';
import { normalizeProductType } from '@utils/normalizers';

const VariantController = ({ product, state, dispatch, showLensController }) => {
	const { query } = useRouter();
	const isMetalBaseFrame = query?.base === 'Metal' || product.handle.includes('mixed-material');
	const type = normalizeProductType(product.type);

	const frameColors: string[] = [];
	const metalFrameColors: string[] = [];

	product.variants.forEach(variant => {
		const color = variant.option;

		if (color === FRAME_COLORS.MIX_BLACK_GOLD || color === FRAME_COLORS.MIX_CRYSTAL_SILVER) {
			metalFrameColors.push(color);
		} else {
			frameColors.push(color);
		}
	});

	if (!(product.variants.length > 1)) return null;

	switch (type) {
		case PRODUCT_TYPES.BASE_FRAME || PRODUCT_TYPES.BASE_FRAME_SR:
			return (
				<>
					<SwatchController
						options={isMetalBaseFrame ? metalFrameColors : frameColors}
						callback={dispatch}
						name={product.name}
						selected={(state as BaseFrameState).frame}
						type={'frame'}
						alignment='left'
						activateOnHover
						parent={'product-card'}
					/>
					{showLensController && (
						<SwatchController
							options={Object.values(Object.values(LENS_COLORS))}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).lens ?? LENS_COLORS.BLACK}
							title={'with'}
							type={'lens'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					)}
				</>
			);

		default:
			return null;
	}
};

export default VariantController;
