import React from 'react';
import * as Sentry from '@sentry/nextjs';

type ErrorBoundaryProps = {
	hasError: boolean;
};

class ErrorBoundary extends React.Component<{ children: JSX.Element; skeleton?: JSX.Element }, ErrorBoundaryProps> {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error({ error, errorInfo });
		Sentry.captureException(error);
	}

	render() {
		return this.state.hasError ? this.props.skeleton || null : this.props.children;
	}
}

export default ErrorBoundary;
