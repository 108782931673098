import { ContentfulCollection, EntrySkeletonType } from 'contentful';
import { useQuery } from '@tanstack/react-query';
import {
	ButtonEntrySkeletonType,
	ChecklistFieldsEntrySkeletonType,
	CollectionListEntrySkeletonType,
	ComponentLayoutEntrySkeletonType,
	ComponentTextEntrySkeletonType,
	DemoListEntrySkeletonType,
	HeroCarouselFieldsEntrySkeletonType,
	HeroEntrySkeletonType,
	ImageTitleTextStackEntrySkeletonType,
	InteractiveCardEntrySkeletonType,
	InteractiveCardFields,
	MarketingCardEntrySkeletonType,
	TestimonialSlide,
	TwoPanelContentCardEntrySkeletonType,
	VisualAssetEntrySkeletonType,
} from '@ts/contentful';
import { normalizeContentfulEntry } from '@utils/normalizers';
import client from '../client';

/** Uses React Query to fetch an array of Asset Entries from Contentful.
 *
 * @param id The ID for the query, should be a meaningful, unique string.
 * @param query An object that determines the parameters to query the content.
 * @param asNormalized A boolean that determines if items should be normalized.
 */

const useContentfulAsset = <
	T extends
		| VisualAssetEntrySkeletonType
		| InteractiveCardEntrySkeletonType
		| ImageTitleTextStackEntrySkeletonType
		| ComponentLayoutEntrySkeletonType
		| ComponentTextEntrySkeletonType
		| HeroEntrySkeletonType
		| HeroCarouselFieldsEntrySkeletonType
		| DemoListEntrySkeletonType
		| ButtonEntrySkeletonType
		| ChecklistFieldsEntrySkeletonType
		| CollectionListEntrySkeletonType
		| TwoPanelContentCardEntrySkeletonType
		| TestimonialSlide
		| MarketingCardEntrySkeletonType
		| InteractiveCardFields,
>({
	id,
	query,
	asNormalized = false,
}: {
	id: string;
	query?: { [key: string]: unknown };
	asNormalized?: boolean;
}) => {
	return useQuery([id], async () => {
		try {
			const response = await client.getEntries<EntrySkeletonType<T>>(query);

			if (asNormalized) {
				const normalizedItems = await Promise.allSettled(response.items.map(normalizeContentfulEntry));
				return (normalizedItems as PromiseFulfilledResult<any>[]).map(item => item.value);
			}

			return response.items as ContentfulCollection<T>['items'];
		} catch (error) {
			throw new Error(`Error fetching in useContentfulAsset: ${error}. Query ID: ${id}`);
		}
	});
};

export default useContentfulAsset;
