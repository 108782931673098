import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { generateSrcSet } from '@utils/images';
import { IMAGE_PLACEHOLDER } from '@constants';

type ImgProps = JSX.IntrinsicElements['img'] & {
	/** When true, the component will not attempt to generate optimally-sized src urls */
	noSrcset?: boolean;
	/** When true, the component will use a placeholder image */
	needsPlaceholder?: boolean;
	aspectRatio?: string;
	height?: number;
	width?: number;
};

const Img = forwardRef<HTMLImageElement, ImgProps>(
	(
		{
			src: srcProp,
			alt,
			srcSet: srcSetProp = null,
			loading = 'lazy',
			noSrcset = false,
			needsPlaceholder = false,
			aspectRatio,
			height,
			width,
			...rest
		},
		ref
	) => {
		const _ref = useRef(null);
		const imageRef = ref ?? _ref;
		const [src, setSrc] = useState(needsPlaceholder ? IMAGE_PLACEHOLDER : srcProp);
		const [srcSet, setSrcSet] = useState(srcSetProp);
		const [loaded, setLoaded] = useState(false);

		useEffect(() => {
			if (src !== IMAGE_PLACEHOLDER && !noSrcset && !srcSetProp) {
				setSrcSet(generateSrcSet(src));
			}
		}, [noSrcset, src, srcProp, srcSetProp]);

		// See https://stackoverflow.com/q/39777833/266535 for why we use this ref
		// handler instead of the img's onLoad attribute.
		useEffect(() => {
			if (typeof imageRef !== 'function') {
				const img = imageRef.current;
				if (img && img.complete) {
					handleImageLoaded();
				}
			}
		});

		useEffect(() => {
			if (loaded) {
				setSrc(srcProp);
			} else {
				needsPlaceholder && setSrc(IMAGE_PLACEHOLDER);
			}
		}, [loaded, needsPlaceholder, srcProp]);

		const config = {
			loading,
		};

		const handleImageLoaded = () => {
			if (!loaded) {
				setLoaded(true);
			}
		};

		return (
			<>
				<img
					ref={imageRef}
					srcSet={srcSet}
					onLoad={handleImageLoaded}
					alt={alt}
					title={alt}
					style={{
						aspectRatio: aspectRatio,
						color: 'transparent',
					}}
					height={height}
					width={width}
					src={src}
					{...config}
					{...rest}
				/>
			</>
		);
	}
);

Img.displayName = 'Img';

export default Img;
