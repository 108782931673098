import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '@context';
import { TOAST } from '@constants';
import { CartMutationArgs, NormalizedCart, NormalizedCartLine } from '@ts/cart';
import { Mutation } from '@ts/shopify-storefront-api';
import { generateCartBundles, normalizeCart } from '@utils/normalizers';
import { cartLinesUpdateMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';

type useCartUpdateMutation = {
	lineItems: CartMutationArgs['lineItems'];
};

const useCartUpdate = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { data: cartId } = useCartId();

	return useMutation(
		['cart', 'update', { cartId }],
		async ({ lineItems }: useCartUpdateMutation) => {
			const updatePayload = lineItems.map(({ id, quantity, variant, customAttributes }) => ({
				id,
				quantity,
				merchandiseId: variant.id,
				attributes: customAttributes,
			}));
			const { cartLinesUpdate }: Mutation = await fetchStorefrontApi(cartLinesUpdateMutation, {
				variables: { cartId, lines: updatePayload },
			});
			const { userErrors, cart } = cartLinesUpdate;

			throwCartErrors(userErrors);
			const normalizedCart = normalizeCart(cart);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
			return normalizedCart;
		},
		{
			onMutate: ({ lineItems }) => {
				queryClient.cancelQueries(['cart']);
				const cartSnapshot = queryClient.getQueryData(['cart', { cartId }]);

				queryClient.setQueryData<NormalizedCart>(['cart', { cartId }], previousCart => {
					const lines = previousCart.lines.reduce((result, current) => {
						const lineToUpdate = lineItems.find(line => line.id === current.id);
						if (lineToUpdate) {
							result.push({
								id: null,
								variant: lineToUpdate.variant,
								properties: Object.assign(
									{},
									...lineToUpdate.customAttributes.map(({ key, value }) => ({ [key]: value }))
								),
								title: null,
								discountAllocations: null,
								quantity: lineToUpdate.quantity,
								optimistic: true,
							});
						} else {
							result.push(current);
						}

						return result;
					}, [] as NormalizedCartLine[]);
					const bundles = generateCartBundles(lines, true);
					const subtotal = lines.reduce((res, cur) => res + cur.variant.price.amount * cur.quantity, 0);

					return { ...previousCart, bundles, lines, subtotal };
				});

				return { cartSnapshot };
			},
			onError: (error, props, context) => {
				console.error(`Error updating cart ${cartId}: ${error}`);
				queryClient.setQueryData(['cart', { cartId }], context.cartSnapshot);
				queryClient.invalidateQueries(['cart']);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
			onSettled: () => {
				queryClient.invalidateQueries(['cart']);
			},
		}
	);
};

export { useCartUpdate };
