import { memo, PropsWithChildren } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import styles from './CutPaper.module.scss';

type CutPaperProps = PropsWithChildren & {
	padding: string;
	cutPaperPath: string;
	className?: string;
	backgroundColor?: string;
	textColor?: string;
	backCutPaperColor?: string;
	style?: React.CSSProperties;
};

const CutPaper = ({
	children,
	className,
	padding,
	cutPaperPath,
	backgroundColor = variables.gray0,
	textColor = '#000',
	...rest
}: CutPaperProps) => {
	return (
		<div className={cn(styles.container, className)} {...rest}>
			<div className={styles.cutpaper} style={{ clipPath: cutPaperPath, backgroundColor, color: textColor }}>
				<div style={{ padding }}>{children}</div>
			</div>
		</div>
	);
};

export default memo(CutPaper);
