import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import styles from './Attention.module.scss';

const Attention = ({ height = 16, label, width = 16, onClick = null, wrapperClass = null, fill = 'none', ...props }) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg
				className={`${styles.icon} ${wrapperClass}`}
				width={width}
				height={height}
				viewBox={`0 0 20 20`}
				onClick={onClick}
				fill={fill}
				{...props}
			>
				<path d='M9.99 20C4.46846 19.9945 -0.00367557 15.5149 2.26684e-06 9.99334C0.00368465 4.47179 4.48179 -0.00183754 10.0033 5.66223e-07C15.5249 0.00183981 20 4.47845 20 10C19.9967 15.5254 15.5154 20.0022 9.99 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.6845 14.444 2.10977 10.0425 2.08599C5.64111 2.06245 2.04732 5.59876 2 10V10.172ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z' />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Attention;
