/* eslint-disable indent */
import {
	generateMetafieldQueries,
	buildFlowCollectionMetafieldIdentifiers,
	variantMetafieldIdentifiers,
} from '../generate-metafield-queries';

export const productFieldsFragment = /* GraphQL */ `
	fragment productFields on Product {
		id
		handle
		title
		availableForSale
		productType
		tags
		totalInventory
		priceRange {
			minVariantPrice {
				amount
				currencyCode
			}
			maxVariantPrice {
				amount
				currencyCode
			}
		}
		variantBySelectedOptions(selectedOptions: $selectedOptions) {
			id
			title
			sku
			compareAtPrice {
				amount
				currencyCode
			}
			requiresShipping
			availableForSale
			price {
				amount
				currencyCode
			}
			image {
				url
				height
				width
			}
			${generateMetafieldQueries(variantMetafieldIdentifiers)}
		}
		${
			// Keep query cost low by only pulling in variant metafields.
			// We can change to `productMetafieldIdentifiers` if/when needed.
			generateMetafieldQueries(variantMetafieldIdentifiers)
		}
	}
`;

const buildFlowCollectionsQuery = /* GraphQL */ `
	query getBuildFlowCollections($searchQuery: String!, $selectedOptions: [SelectedOptionInput!]!, $cursor: String, $country: CountryCode = US ) @inContext(country: $country) {
		collections(first: 50, query: $searchQuery) {
			edges {
				cursor
				node {
					id
					title
					handle
					image {
						url
						height
						width
					}
					products(first: 50, after: $cursor, filters: [{available: true}]) {
						edges {
							cursor
							node {
								...productFields
							}
						}
						pageInfo {
							hasNextPage
						}
					}
					${generateMetafieldQueries(buildFlowCollectionMetafieldIdentifiers)}
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}

	${productFieldsFragment}
`;

export const buildFlowCollectionQuery = /* GraphQL */ `
query getBuildFlowCollection($handle: String!, $selectedOptions: [SelectedOptionInput!]!, $cursor: String) {
	collection(handle: $handle) {
		id
		title
		handle
		image {
			url
			height
			width
		}
		products(first: 50, after: $cursor, filters: [{available: true}]) {
			edges {
				cursor
				node {
					...productFields
				}
			}
			pageInfo {
				hasNextPage
			}
		}
		${generateMetafieldQueries(buildFlowCollectionMetafieldIdentifiers)}
	}
}

${productFieldsFragment}
`;

export default buildFlowCollectionsQuery;
