export const generateRandomNum = (min, max) => {
	return Math.random() * (max - min + 0.5) + min;
};

// Helpers for seeded rng –– see the linked answer for more info
// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript/47593316#47593316

/** Given a string, produces a 128-bit hash value that can be used to seed a Pseudorandom number generator (PRNG) */
export const cyrb128 = (str: string) => {
	let h1 = 1779033703,
		h2 = 3144134277,
		h3 = 1013904242,
		h4 = 2773480762;
	for (let i = 0, k; i < str.length; i++) {
		k = str.charCodeAt(i);
		h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
		h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
		h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
		h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
	}

	h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
	h2 = h3 ^ Math.imul(h4 >>> 22, 2869860233);
	h3 = h4 ^ Math.imul(h1 >>> 17, 951274213);
	h4 = h1 ^ Math.imul(h2 >>> 19, 2716044179);

	return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
};

export const mulberry32 = (a: number) => {
	return () => {
		let t = (a += 0x6d2b79f5);
		t = Math.imul(t ^ (t >>> 15), t | 1);
		t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
		return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
	};
};

/** Uses a seed string to create a predictable random number generator function.  */
export const createSeededRNG = (seed: string) => {
	return (min: number, max: number) => {
		const seedHash = cyrb128(seed)[0];
		const rng = mulberry32(seedHash);
		return (rng() * (max - min) + min).toPrecision(3);
	};
};
