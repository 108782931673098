/* eslint-disable max-lines */
import { ReactNode, useEffect, useState } from 'react';
import localFont from 'next/font/local';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRouter, NextRouter } from 'next/router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { LazyMotion } from 'framer-motion';
import aa from 'search-insights';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalStorageStickyBucketService } from '@growthbook/growthbook';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { ErrorBoundary, GlobalScripts, InternationalWelcomeModal, Layout, ToastViewport } from '@components';
import { ToastProvider as GlobalToast, CartProvider, LocaleProvider, AllTopsDrawerProvider } from '@utils/context';
import '@styles/global.scss';
import { trackPageView } from '@services/analytics/trackers';
import { MOBILE_MAX_WIDTH, UTM_MEDIUM, getShopifyIdFromGID } from '@utils/index';
import { handleKlaviyoPopupSubmission, updateHeapEventProperties, handleKlaviyoPopupViewed } from '@services/analytics/heap';
import('@ts/contentful/registered-components');
import { getUUID } from '@services/analytics/growthbook';

const platform = localFont({
	src: [
		{
			path: '../public/fonts/Platform-Light.woff2',
			weight: '300',
			style: 'normal',
		},
		{
			path: '../public/fonts/Platform-Medium.woff2',
			weight: '500',
			style: 'normal',
		},
	],
	display: 'swap',
	preload: true,
	variable: '--platform',
});

const poppins = localFont({
	src: [
		{
			path: '../public/fonts/Poppins-Light.woff2',
			weight: '300',
			style: 'normal',
		},
		{
			path: '../public/fonts/Poppins-Regular.woff2',
			weight: '400',
			style: 'normal',
		},
		{
			path: '../public/fonts/Poppins-Medium.woff2',
			weight: '500',
			style: 'normal',
		},
		{
			path: '../public/fonts/Poppins-Bold.woff2',
			weight: '700',
			style: 'normal',
		},
	],
	display: 'swap',
	preload: true,
	variable: '--poppins',
});

type GetLayoutProps = (
	page: JSX.Element,
	pageProps: {
		[k: string]: unknown;
	},
	router?: NextRouter
) => ReactNode;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000,
			cacheTime: 2000,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	},
});

const loadFeatures = () => import('@utils/framer-features').then(res => res.default);

const growthbook = new GrowthBook({
	apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
	clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
	enableDevMode: true,
	stickyBucketService: new LocalStorageStickyBucketService(),
	navigate: url => window?.location.replace(url),
	navigateDelay: 200,
	trackingCallback: (experiment, result) => {
		window.heap?.track(`Viewed Experiment`, {
			experimentId: experiment.key,
			experimentName: experiment.name,
			variationId: result.variationId,
		});
		updateHeapEventProperties({
			experimentId: experiment.key,
			variationId: result.variationId,
		});
	},
});

const updateGrowthBookURL = () => {
	growthbook.setURL(window.location.href);
};

function App({ Component, err, pageProps }) {
	const getLayout: GetLayoutProps = Component.getLayout || ((page: React.FC) => <Layout>{page}</Layout>);
	const [isKlaviyoPopUpViewed, setIsKlaviyoPopUpViewed] = useState(false);
	const router = useRouter();

	useEffect(() => {
		growthbook.loadFeatures({ autoRefresh: true });
		const mql = window.matchMedia(`(min-width: ${MOBILE_MAX_WIDTH}px)`);
		const isDesktop = mql.matches;

		const gbuuid = getUUID();
		growthbook.setAttributes({
			...growthbook.getAttributes(),
			cookieId: gbuuid,
			deviceType: isDesktop ? 'desktop' : 'mobile',
		});

		(async () => {
			trackPageView(router.asPath);
		})();
	}, []);

	useEffect(() => {
		document.body.className = pageProps ? pageProps.page : 'default';
	}, [pageProps]);

	useEffect(() => {
		// yotpo snippet de-duper

		// select the node that will be observed for mutations
		const headNode = document.querySelector('head');

		const headConfig = { childList: true };

		const callback = () => {
			// Remove the JSON-LD data generated by Yotpo to avoid conflicts with the JSON-LD generated in `SEO`
			const found = document.querySelectorAll('.y-rich-snippet-script');
			found.forEach(el => el.remove());

			// Add a listener to Klaviyo Popup submissions so that we can sync the event with Heap
			try {
				const klaviyoPopupButton = [
					...document.querySelectorAll<HTMLButtonElement>('form[data-testid*="klaviyo-form"] button'),
				].filter(b => b.textContent.includes('Submit'))[0];
				if (klaviyoPopupButton) {
					klaviyoPopupButton.addEventListener('click', handleKlaviyoPopupSubmission);
				}
			} catch (error) {
				console.error(`Error tracking Popup submission: ${error}`);
			}
		};

		const headObserver = new MutationObserver(callback);

		headObserver.observe(headNode, headConfig);

		aa('init', {
			appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
			apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
			useCookie: true,
		});

		return () => {
			headObserver.disconnect();
		};

		// This effect should only run on mount.
	}, []);

	useEffect(() => {
		const bodyNode = document.querySelector('body');
		const bodyConfig = { childList: true, subtree: true };

		const bodyObserver = new MutationObserver(() => {
			// Add a listener to Klaviyo Popup being rendered
			try {
				const klaviyoPopup = document.querySelector<HTMLInputElement>('form[data-testid*="klaviyo-form"]');
				if (klaviyoPopup && !isKlaviyoPopUpViewed) {
					handleKlaviyoPopupViewed();
					setIsKlaviyoPopUpViewed(true);
				}
			} catch (error) {
				console.error(`Error tracking Popup submission: ${error}`);
			}
		});

		bodyObserver.observe(bodyNode, bodyConfig);

		return () => {
			bodyObserver.disconnect();
		};
	}, [isKlaviyoPopUpViewed]);

	useEffect(() => {
		window.locale = router.locale;
		const handleRouteChangeComplete = async url => {
			// Code in this function enables accurate tracking on every page change
			await updateHeapEventProperties();
			await trackPageView(url);
		};

		// This function call is duplicated so that heap can accurately track logged-in events on mount.
		updateHeapEventProperties();
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
	}, [router.events]);

	useEffect(() => {
		growthbook.loadFeatures({ autoRefresh: true });
		const mql = window.matchMedia(`(min-width: ${MOBILE_MAX_WIDTH}px)`);
		const isDesktop = mql.matches;

		// Need timeout to catch Heap ID
		setTimeout(() => {
			growthbook.setAttributes({
				...growthbook.getAttributes(),
				id: window.heap?.userId,
				browser: navigator.userAgent,
				country: router.locale,
				deviceType: isDesktop ? 'desktop' : 'mobile',
				utmSource: router.query['utm_source'],
				utmMedium: router.query[UTM_MEDIUM],
				utmCampaign: router.query['utm_campaign'],
				loggedin: !!growthbook.getAttributes().email,
			});
		}, 600);

		// Subscribe to route change events and update GrowthBook
		router.events.on('routeChangeComplete', updateGrowthBookURL);
		return () => router.events.off('routeChangeComplete', updateGrowthBookURL);
	}, [router.query]);

	// Using "Pyramid of Doom" to make algolia instantsearch router nextjs work
	return (
		<div className='mainAppContainer'>
			<style jsx global>
				{`
					:root {
						--platform: ${platform.style.fontFamily};
						--poppins: ${poppins.style.fontFamily};
					}
				`}
			</style>
			<GrowthBookProvider growthbook={growthbook}>
				<ErrorBoundary>
					<GlobalToast>
						<QueryClientProvider client={queryClient}>
							<CartProvider>
								<LocaleProvider>
									<AllTopsDrawerProvider>
										<Hydrate state={pageProps.dehydratedState}>
											<LazyMotion strict features={loadFeatures}>
												<TooltipProvider delayDuration={0} skipDelayDuration={0}>
													<div
														id='swell-customer-identification'
														data-authenticated='true'
														data-email={pageProps?.customer?.email}
														data-id={getShopifyIdFromGID(pageProps?.customer?.id)}
														data-tags={JSON.stringify(pageProps?.customer?.tags)} // Needs to be a JSON string per Yotpo documentation
														style={{ display: 'none' }}
													></div>
													<InternationalWelcomeModal />
													{getLayout(<Component {...{ ...pageProps }} err={err} />, pageProps, router)}
													<ToastViewport />
													<ReactQueryDevtools />
													<GlobalScripts />
												</TooltipProvider>
											</LazyMotion>
										</Hydrate>
									</AllTopsDrawerProvider>
								</LocaleProvider>
							</CartProvider>
						</QueryClientProvider>
					</GlobalToast>
				</ErrorBoundary>
			</GrowthBookProvider>
			<SpeedInsights sampleRate={0.05} />
		</div>
	);
}

export default App;
