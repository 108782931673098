import { FC } from 'react';
import { TypographyProps } from '@ts/components';

const defaultStyle = {
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '1.4rem',
	lineHeight: '150%',
	marginInline: '0.4rem',
};

/**
 * Dash
 *
 * @default
 * - Size: 1.4rem
 * - Weight: 400
 * - Height: 150%
 * - Family: var(--poppins)
 * - Margin-X: 0.4rem
 */
const Dash: FC<TypographyProps<HTMLSpanElement>> = ({ style, ...props }) => (
	<span style={{ ...defaultStyle, ...style }} {...props}>
		{'-'}
	</span>
);

export default Dash;
