import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { Mutation } from '@ts/shopify-storefront-api';
import { cartBuyerIdentityUpdateMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';

type useCartUpdateBuyerMutation = {
	customerAccessToken?: string;
	email?: string;
	countryCode?: string;
};

const useCartUpdateBuyer = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { data: cartId } = useCartId();

	return useMutation(
		['cart', 'updateBuyer', { cartId }],
		async ({ customerAccessToken, email, countryCode }: useCartUpdateBuyerMutation) => {
			if (!cartId) return;
			const { cartBuyerIdentityUpdate }: Mutation = await fetchStorefrontApi(cartBuyerIdentityUpdateMutation, {
				variables: { cartId, buyerIdentity: { customerAccessToken, email, countryCode } },
			});
			const { userErrors } = cartBuyerIdentityUpdate;

			throwCartErrors(userErrors);
			queryClient.invalidateQueries(['cart', { cartId }]);
		},
		{
			onError: error => {
				console.error(`Error updating buyer in cart ${cartId}: ${error}`);
				queryClient.invalidateQueries(['cart', cartId]);
			},
		}
	);
};

export { useCartUpdateBuyer };
