import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const TikTokIcon = (): JSX.Element => {
	return (
		<AccessibleIcon.Root label='tiktok'>
			<svg xmlns='http://www.w3.org/2000/svg' width='19' height='22' viewBox='0 0 19 22' fill='none'>
				<path
					d='M18.4976 8.99179C18.3266 9.00865 18.1532 9.0183 17.9797 9.0183C16.0865 9.0183 14.3186 8.06445 13.2804 6.47955V15.1267C13.2804 18.6554 10.4189 21.5169 6.89021 21.5169C3.36151 21.5169 0.5 18.6554 0.5 15.1267C0.5 11.598 3.36151 8.73647 6.89021 8.73647C7.02269 8.73647 7.15514 8.74852 7.28521 8.75575V11.9039C7.15514 11.887 7.0251 11.8653 6.89021 11.8653C5.08853 11.8653 3.62886 13.325 3.62886 15.1267C3.62886 16.9284 5.08853 18.388 6.89021 18.388C8.6919 18.388 10.2841 16.9693 10.2841 15.1676L10.3153 0.484375H13.3286C13.6128 3.1869 15.7902 5.2969 18.5 5.49441V8.9942'
					fill='black'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default TikTokIcon;
