import { getAsyncError, getError } from '@services/handle-fetch-response';
import { Dict } from '@ts/general';
import sleep from '@utils/sleep';

const MAX_RETRIES = 6; // won't wait longer than 2 * 2^6 = 128 seconds

const fetchStorefrontApi = async (query: string, { variables }: { variables: Dict }, fetchOptions: Dict = null, waitTime = 0) => {
	try {
		const { locale, ...vars } = variables ?? {};
		const res = await fetch(process.env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_URL, {
			...fetchOptions,
			method: 'POST',
			headers: {
				...fetchOptions?.headers,
				...(locale && {
					'Accept-Language': locale,
				}),
				'Content-Type': 'application/json',
				'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN,
			},
			body: JSON.stringify({
				query,
				variables: vars,
			}),
		});

		// Respect Rate-Limit
		if (res.ok) {
			const { data, errors } = await res.json();
			if (res.status === 200 && errors?.[0]?.message === 'Throttled') {
				if (waitTime > MAX_RETRIES) throw new Error('Hit Max Retries');
				console.error(`hit rate limit, waiting for ${(2000 * 2 ** waitTime) / 1000} seconds ...`);
				await sleep(2000 * 2 ** waitTime);
				return await fetchStorefrontApi(query, { variables }, fetchOptions, waitTime + 1);
			} else if (errors && errors.length) {
				throw getError(errors, res.status);
			} else {
				return data;
			}
		} else {
			throw await getAsyncError(res);
		}
	} catch (err) {
		throw getError(
			[
				{
					message: err,
				},
			],
			500
		);
	}
};

export default fetchStorefrontApi;
