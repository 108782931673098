import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useContentfulAsset } from '@services/contentful';
import { getProductVariant } from '@services/shopify';
import { generateBase64ShopifyId, getShopifyIdFromBase64 } from '@utils/shopify';

const useIsProductVariantSoldOut = () => {
	const { data } = useContentfulAsset({
		id: 'productList',
		query: {
			'content_type': 'productList',
			'fields.slug': 'sold-out',
		},
	});

	const query = useQuery(['sold-out-product-variants'], async () => {
		if (!(!!data && !!data[0].fields.products)) return [];

		const productList = data[0].fields.products;
		const productIDs = productList.map(_handle => {
			const shopifyProductVariantId = getShopifyIdFromBase64(_handle);
			return shopifyProductVariantId;
		});

		return await Promise.all(
			productIDs.map((id: string) => {
				const commonId = generateBase64ShopifyId(id, 'ProductVariant');
				return getProductVariant(commonId);
			})
		);
	});

	useEffect(() => {
		query.refetch();
	}, [data]);

	const checkIfSoldOut = useCallback(
		(handle: string, title: string) => {
			return (
				query.data?.some(variant => {
					return variant?.node?.product?.handle === handle && variant?.node?.title === title;
				}) || false
			);
		},
		[query.data]
	);

	return {
		checkIfSoldOut,
	};
};

export default useIsProductVariantSoldOut;
