import handleFetchResponse from '@services/handle-fetch-response';
import { DoctorInfo } from '@ts/poms';

export const sendDoctorInfo = async (info: DoctorInfo) => {
	try {
		const res = await fetch(`${process.env.NEXT_PUBLIC_POMS_URL}/api/doctor/upload`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(info),
		});
		return await handleFetchResponse(res);
	} catch (error) {
		console.error(`Error sending doctor info to POMS: ${error}`);
		throw error;
	}
};
