import { useEffect, useState } from 'react';
import { useHits } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { PRODUCT_TYPES } from '@constants';
import { Grid, Title } from '@components';
import { trackSearch } from '@services/analytics/trackers';
import { normalizeProductType } from '@utils/normalizers/normalize-product';
import { useDebounce, useIsProductVariantSoldOut } from '@utils/hooks';
import { useCartContext } from '@context';
import Hit from '../Hit/Hit';
import styles from './Hits.module.scss';

const { BASE_FRAME, TOP_FRAME, ACCESSORY, GIFT_CARD } = PRODUCT_TYPES;

// NOTE: Turn into new component
const HitGroup = ({ title, values, locale }) =>
	values ? (
		<>
			<Title style={{ marginBottom: '1.6rem' }}>{title}</Title>
			<Grid columns={2} gap={3} className={styles.resultsGrid}>
				{values.map((v, i) => (
					<Hit locale={locale} key={v.objectID} hit={v} position={i + 1} />
				))}
			</Grid>
		</>
	) : null;

const Hits = props => {
	const { setSearchQueryId } = useCartContext();
	const [locale, setLocale] = useState('en-US');
	const {
		hits,
		results: { queryID },
	} = useHits(props);

	const debouncedTrackSearch = useDebounce(trackSearch, 300);
	useEffect(() => {
		if (hits.length) debouncedTrackSearch(hits);
	}, [hits]);

	const router = useRouter();
	const { checkIfSoldOut } = useIsProductVariantSoldOut();
	const filteredHits = hits.filter(hit => {
		const isSoldOut = checkIfSoldOut(hit.handle as string, hit.variant_title as string);
		return !isSoldOut;
	});

	useEffect(() => {
		router?.isReady && setLocale(router.locale);
	}, [router]);

	useEffect(() => {
		setSearchQueryId(queryID);
	}, [queryID, setSearchQueryId]);

	const groupedByType =
		props.groupHits &&
		hits.reduce((prev, current) => {
			const type = normalizeProductType(current.product_type);
			(prev[type] = prev[type] || []).push(current);
			return prev;
		}, {});

	return props.groupHits && props.visible ? (
		<>
			<HitGroup locale={locale} title='Base Frames' values={groupedByType[BASE_FRAME]} />
			<HitGroup locale={locale} title='Top Frames' values={groupedByType[TOP_FRAME]} />
			<HitGroup locale={locale} title='Accessories' values={groupedByType[ACCESSORY]} />
			<HitGroup locale={locale} title='Gift Cards' values={groupedByType[GIFT_CARD]} />
		</>
	) : (
		<div className={`${styles.container} ${props.visible ? '' : styles.hidden}`}>
			{filteredHits.length > 0 && <Title className={styles['title']}>Product Suggestions</Title>}
			<ol className={styles['hits']}>
				{filteredHits.map((hit, index) => {
					return (
						<li key={hit.objectID}>
							<Hit locale={locale} hit={hit} position={index + 1} type='search' />
						</li>
					);
				})}
			</ol>
		</div>
	);
};

export default Hits;
