import { FC, forwardRef } from 'react';
import { TypographyProps } from '@ts/components';

const defaultStyle = {
	fontFamily: 'var(--poppins)',
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '1.6rem',
	lineHeight: '2.4rem',
};

/**
 *  Body
 *
 * @default
 * - Size: 1.6rem
 * - Weight: 400
 * - Height: 2.4rem
 * - Family: var(--poppins)
 */
const Body: FC<TypographyProps<HTMLParagraphElement>> = forwardRef(({ children, style, ...rest }, forwardedRef) => {
	return (
		<p
			ref={forwardedRef}
			style={{
				...defaultStyle,
				...style,
			}}
			{...rest}
		>
			{children}
		</p>
	);
});

Body.displayName = 'Body';

export default Body;
