import { QueryRoot } from '@ts/shopify-storefront-api';
import cleanGraphqlResponse from '@services/clean-graphql-response';
import fetchStorefrontApi from '../fetch-storefront-api';
import { bfroDiscountQuery } from '../queries';

export type CustomerOrdersResponse = {
	customer: {
		orders: {
			lineItems: {
				variant: {
					product: {
						productType: string;
					};
				};
			}[];
		}[];
	};
};

export const getBfroDiscount = async (customerAccessToken: string | undefined) => {
	if (!customerAccessToken) return null;
	const dataToClean: QueryRoot = await fetchStorefrontApi(bfroDiscountQuery, {
		variables: { customerAccessToken },
	});
	const { customer } = cleanGraphqlResponse<CustomerOrdersResponse>(dataToClean);

	return customer;
};
