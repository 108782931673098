import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { TypographyProps } from '@ts/components';
import styles from './Detail.module.scss';

/**
 * Detail
 *
 * @sizes
 * - Default
 *   - Size: 1rem
 * @default
 * - Weight: 500
 * - Height: 150%
 * - Family: var(--poppins)
 */
const Detail: FC<TypographyProps<HTMLSpanElement>> = forwardRef(({ children, className, style, ...props }, forwardedRef) => {
	return (
		<span
			style={{
				...style,
			}}
			ref={forwardedRef}
			className={cn(styles['default'], className)}
			{...props}
		>
			{children}
		</span>
	);
});

Detail.displayName = 'Detail';

export default Detail;
