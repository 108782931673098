import { createContext, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage, useLocale } from '@utils/hooks';
import { LOCALE_CODES, LOCALE_DICT } from '@constants';
import { useCartContext } from '@context';
import { useCart } from '@services/shopify';

const LocaleContext = createContext({
	detectedLocale: LOCALE_CODES.US,
	openModal: false,
	setOpenModal: undefined,
	handleLocaleChange: undefined,
	handleModalClose: undefined,
});

export const LocaleProvider = ({ children }) => {
	const { locale: routerLocale, pathname, asPath, push, query } = useRouter();
	const { data: detectedLocale } = useLocale();
	const [userLocale, setUserLocale] = useLocalStorage('userLocale', null);
	const [openModal, setOpenModal] = useLocalStorage('showWelcomeModal', false);
	const { handleUpdateBuyer } = useCartContext();
	const { data: cart } = useCart();

	function handleLocaleChange(newCountryCode: string) {
		const newLocaleCode = LOCALE_CODES[newCountryCode];
		setUserLocale(newLocaleCode);
		handleUpdateBuyer({ countryCode: newCountryCode });
		window.locale = newLocaleCode;
		push({ pathname, query }, asPath, { locale: newLocaleCode, shallow: true });
		setOpenModal(true);
	}

	function handleModalClose(newLocale) {
		if (!!userLocale) {
			setUserLocale(newLocale);
		}
		setOpenModal(false);
	}

	useEffect(() => {
		const isDiffDetectedLocale = !!LOCALE_DICT[detectedLocale] && routerLocale !== detectedLocale;

		if (!!userLocale) {
			if (routerLocale !== userLocale) {
				handleUpdateBuyer({ countryCode: LOCALE_DICT[userLocale].countryCode });
				window.locale = userLocale;
				push({ pathname, query }, asPath, { locale: userLocale });
				setOpenModal(true);
			}
		} else {
			if (isDiffDetectedLocale) {
				!!cart && handleUpdateBuyer({ countryCode: LOCALE_DICT[detectedLocale].countryCode });
				window.locale = detectedLocale;
				setUserLocale(detectedLocale);
				push({ pathname, query }, asPath, { locale: detectedLocale });
				setOpenModal(true);
			}
		}
	}, [routerLocale, detectedLocale, userLocale]);

	useEffect(() => {
		window.GLBE_PARAMS = {
			countryCode: LOCALE_DICT[routerLocale].countryCode,
			currencyCode: LOCALE_DICT[routerLocale].currencyCode,
			isExternal: true,
		};
	}, [routerLocale]);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://crossborder-integration.global-e.com/resources/js/app?shop=pair-eyewear.myshopify.com';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			if (script) {
				script.remove();
			}
		};
	}, []);

	return (
		<LocaleContext.Provider
			value={{
				detectedLocale,
				openModal,
				setOpenModal,
				handleLocaleChange,
				handleModalClose,
			}}
		>
			{children}
		</LocaleContext.Provider>
	);
};

export function useLocaleContext() {
	return useContext(LocaleContext);
}
