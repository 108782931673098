import { productVariantFragment } from '@services/shopify/fragments/variant';
import { cartErrorFragment } from '@services/shopify/fragments/cart';

const cartLinesRemoveMutation = /* GraphQL */ `
	mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				id
				checkoutUrl
				lines(first: 100) {
					edges {
						node {
							id
							quantity
							merchandise {
								...productVariantFragment
							}
							attributes {
								key
								value
							}
							cost {
								subtotalAmount {
									amount
									currencyCode
								}
								totalAmount {
									amount
									currencyCode
								}
							}
						}
					}
				}
				totalQuantity
			}
			userErrors {
				...cartErrorFragment
			}
		}
	}

	${productVariantFragment}
	${cartErrorFragment}
`;

export { cartLinesRemoveMutation };
