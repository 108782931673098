import { CartErrorCode, CartUserError } from '@ts/shopify-storefront-api';
import { PairError } from '.';

const getCustomMessage = (code: CartErrorCode, message: string) => {
	switch (code) {
		case 'INVALID':
			message = 'Invalid cart creation input';
			break;
	}
	return message;
};

export const throwCartErrors = (errors?: CartUserError[]) => {
	if (errors && errors.length) {
		throw new PairError({
			errors: errors.map(({ code, message }) => ({
				code: code ?? 'cart_error',
				message: getCustomMessage(code, message),
			})),
		});
	}
};
