import { SubscriberInfo } from '@ts/prive';

export default async function getSubscriber(id: string): Promise<SubscriberInfo> {
	try {
		if (!id) throw new Error('No id provided');
		const priveURL = `${process.env.NEXT_PUBLIC_PRIVE_URL}/api/v1/subscribers?limit=10&page=1&externalId=${id}`;

		const res = await fetch(priveURL, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${process.env.NEXT_PUBLIC_PRIVE_API_TOKEN}`,
			},
		});

		if (!res.ok) {
			throw new Error('Failed to fetch subscriber');
		}

		const data = await res.json();
		return data.subscribers[0];
	} catch (error) {
		return null;
	}
}
