import { createSeededRNG } from './math';

/** Creates a random `clipPath` value in the shape of cut-up, or ripped paper.
 *
 * The provided seed string ensures that Server-Rendered values persist on the Client, avoiding hydration errors */
export default function createCutPaperPath(seed: string) {
	const rng = createSeededRNG(seed);
	return `polygon(${rng(31.5, 33)}% ${rng(3.5, 5.5)}%,${rng(71.2, 75)}% ${rng(1.5, 4)}%, 97% ${rng(0.5, 3)}%, 100% ${rng(
		85.5,
		88.5
	)}%, ${rng(74.6, 80)}% ${rng(86, 91)}%,  ${rng(39.7, 43)}% ${rng(85.8, 90)}%, ${rng(0, 2)}% ${rng(86.3, 92)}%, ${rng(
		0,
		2
	)}% ${rng(5.5, 8.5)}%)`;
}
