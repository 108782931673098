import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { TypographyProps } from '@ts/components';
import styles from './Label.module.scss';

/**
 * Label
 *
 * @default
 * - Size: 1.4rem
 * - Weight: 500
 * - Height: 1.7rem
 * - Spacing: 0.002em
 * - Family: var(--platform)
 */
const Label: FC<TypographyProps<HTMLSpanElement>> = forwardRef(({ children, style, small, color, ...props }, forwardedRef) => {
	return (
		<span
			style={{
				...style,
				...(color && { color }),
			}}
			className={cn(styles['default'], {
				[styles['small']]: small,
			})}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</span>
	);
});

Label.displayName = 'Label';

export default Label;
