import { createClient, Entry, EntrySkeletonType } from 'contentful';

const preview = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'true';
const host = preview ? 'preview.contentful.com' : 'cdn.contentful.com';
const accessToken = preview
	? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
	: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;

const client = createClient({
	space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
	accessToken,
	host,
});

export type SearchParams = {
	content_type: ContentType;
	limit?: number;
	include?: number;
	[k: string]: unknown;
};

export type ContentType =
	| 'demoList'
	| 'collectionList'
	| 'homepageHeroCarousel'
	| 'homepageHeroCarouselSlide'
	| 'componentButton'
	| 'componentButtonGrouping'
	| 'componentCountdownBanner'
	| 'componentHero'
	| 'componentHeroCarousel'
	| 'componentLayout'
	| 'componentLoggedInMarketingGrid'
	| 'componentLoggedInAccountCard'
	| 'componentTwoPanelContentCard'
	| 'componentProductList'
	| 'frequentlyAskedQuestions'
	| 'marketingBlocks'
	| 'newsletter'
	| 'plpMarketingBlock'
	| 'blockPlp'
	| 'imageTitleTextGroup'
	| 'copyGroup'
	| 'buildFlowData'
	| 'pdMeasure'
	| 'visualAsset'
	| 'testimonialSlider'
	| 'imageTitleTextStack'
	| 'menus';

export const fetchContentful = async <T>(query: SearchParams): Promise<Entry<EntrySkeletonType<T>>> => {
	const { items } = await client.getEntries<EntrySkeletonType<T>>(query);
	return items[0] as Entry<EntrySkeletonType<T>>;
};

export default client;
