import { useQuery } from '@tanstack/react-query';
import { COLLECTION_LISTS } from '@constants/contentful';
import { CleanedCollectionsToSort, getCollectionList } from '@services/contentful';
import { normalizeCollectionsByFrame } from '@utils/normalizers';
import { NormalizedCollection, CleanedBuildFlowCollection, NormalizedBuildFlowCollection } from '@ts/product';
import { CollectionQuerySectionTypes, UseCollectionsQueryOptions } from './types';
import { sortCollectionOrderFromContentful, transformActiveCollections, transformCollections } from './utils';

/**
 * @name useCollectionsQuery
 * General purpose hook for fetching collections clientside using the Contentful and Shopify APIs.
 *
 * @note May require future testing.
 * @returns {NormalizedCollection[]} collections
 */
export const useCollectionsQuery = (
	slug: string = COLLECTION_LISTS.EVERGREEN,
	{
		queryKey = undefined,
		queryRefreshVars = [],
		type,
		maxProducts,
		withProducts = true,
		skipImages = false,
		includeSpecificFrameVariant = false,
		skipVariants = false,
		selectedOptions,
		country,
	}: UseCollectionsQueryOptions = {}
) => {
	if (slug === COLLECTION_LISTS.EVERGREEN)
		console.error(
			`Defaulting to EVERGREEN COLLECTION LIST! Please review Contentful to verify there are no missing entries within CollectionList OR DemoList.`
		);
	return useQuery(
		[queryKey ?? slug, ...queryRefreshVars],
		async () => {
			const props = await getCollectionList({
				slug,
				type,
				withProducts,
				skipImages,
				maxProducts,
				includeSpecificFrameVariant,
				skipVariants,
				selectedOptions,
				country,
			});
			return transformCollectionList(props, type);
		},
		{
			retry: 3,
		}
	);
};

const transformCollectionList = (
	[collections, map]: CleanedCollectionsToSort,
	type: CollectionQuerySectionTypes
): NormalizedCollection[] | NormalizedBuildFlowCollection[] => {
	if (type === 'active') return transformActiveCollections([collections, map]);
	if (type === 'buildflow') {
		const normalized = normalizeCollectionsByFrame(collections as CleanedBuildFlowCollection[]);
		return sortCollectionOrderFromContentful({ collections: normalized, map });
	}

	// Currently only active and buildflow are used
	return transformCollections([collections, map]);
};
