import cn from 'classnames';
import { InformationIcon, LabelText, Paragraph, Tooltip } from '@components';
import variables from '@styles/export.module.scss';
import { useBfroDiscount, useCustomer } from '@services/shopify';
import styles from './DiscountBanner.module.scss';

const { blue3 } = variables;

type DiscountBannerProps = {
	margin?: 'mobile' | 'bottom' | 'extra';
};

function DiscountBanner({ margin = null }: DiscountBannerProps) {
	const { data } = useCustomer();
	const { data: isBfroDisount } = useBfroDiscount();
	const showBanner = !!data && isBfroDisount;

	return showBanner ? (
		<div
			className={cn(
				styles.container,
				{ [styles.mobileMargin]: margin === 'mobile' },
				{ [styles.bottomMargin]: margin === 'bottom' },
				{ [styles.extraMargin]: margin === 'extra' }
			)}
		>
			<Tooltip message='This one-time offer can only be applied to 1 Base Frame & cannot be combined with other discounts.'>
				<InformationIcon width={20} height={20} color={blue3} className={styles.info} />
			</Tooltip>
			<Paragraph className={styles.paragraph}>
				For your eyes only! Get $6 Off Your Next Base Frame With Code <LabelText>NEXTPAIR</LabelText>
			</Paragraph>
		</div>
	) : null;
}

export default DiscountBanner;
