import { CSSProperties, memo, ReactNode, useState } from 'react';
import { BASE_FRAME_NAMES } from '@constants';
import { NormalizedVariant } from '@ts/product';
import { Flex, Img, Modal, Paragraph, Swipe, Zoom } from '@components';
import { slideUp } from '@utils/motions';
import styles from './LightBox.module.scss';

type LightBoxProps = {
	frameShape?: string | (typeof BASE_FRAME_NAMES)[number];
	variant: NormalizedVariant;
	position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
	allowActions?: boolean;
	className?: string;
	width?: number | string;
	height?: number | string;
	iconOnly?: boolean;
	shape?: 'square';
	style?: CSSProperties;
	children?: ReactNode;
	dataTag: object;
};

const LightBox = ({ variant, shape, iconOnly = undefined, width, height, dataTag }: LightBoxProps) => {
	const [isHiddenBanner, setIsHiddenBanner] = useState(false);
	const { image } = variant;

	return (
		<Modal>
			<Modal.Trigger asChild {...dataTag}>
				<Zoom shape={shape} iconOnly={iconOnly} width={width} height={height} />
			</Modal.Trigger>
			<Modal.Content className={styles.modal} customAnimation={slideUp}>
				<div
					className={styles.imageContainer}
					onTouchMoveCapture={() => {
						if (!isHiddenBanner) {
							setIsHiddenBanner(true);
						}
					}}
				>
					<Img
						className={styles.image}
						src={image.url}
						alt={image.altText}
						width={image.width ?? 800}
						height={image.height ?? 350}
					/>
					<Flex
						align='center'
						justify='center'
						className={`${styles.bottomMessage} ${isHiddenBanner ? styles.hiding : ''}`}
					>
						<Swipe />
						<Paragraph>Swipe to view more</Paragraph>
					</Flex>
				</div>
			</Modal.Content>
		</Modal>
	);
};

export default memo(LightBox);
