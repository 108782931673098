import { Root } from '@radix-ui/react-accessible-icon';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import styles from './Close.module.scss';

const Close = ({
	color = variables.gray5,
	height = 16,
	label,
	width = 16,
	onClick = null,
	wrapperClass = null,
	noBorder = false,
	...props
}) => {
	const classes = cn([styles.icon, wrapperClass], {
		[styles.noBorder]: noBorder,
	});

	return (
		<div
			className={classes}
			onClick={onClick}
			style={{ width: `${(width * 2) / 10}rem`, height: `${(height * 2) / 10}rem` }}
			{...props}
		>
			<Root label={label}>
				<svg width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill='none'>
					<path
						d={`M${height - 2} 2L2 ${height - 2}`}
						stroke={color}
						strokeWidth='1.5'
						strokeLinecap='square'
						strokeLinejoin='round'
					/>
					<path
						d={`M2 2L${height - 2} ${height - 2}`}
						stroke={color}
						strokeWidth='1.5'
						strokeLinecap='square'
						strokeLinejoin='round'
					/>
				</svg>
			</Root>
		</div>
	);
};

export default Close;
