import Link from 'next/link';
import { AccountDropdown, AccountIcon } from '@components';
import styles from './AccountButton.module.scss';

type AccountButtonProps = {
	customer: object;
};

const AccountButton = ({ customer }: AccountButtonProps) => {
	return (
		<>
			{customer ? (
				<AccountDropdown customer={customer} />
			) : (
				<Link href={'/account/login'}>
					<button
						type='button'
						data-nav-icon='account'
						className={styles['nav-icon']}
						data-test={customer ? 'account' : 'login'}
						tabIndex={0}
					>
						<AccountIcon loggedIn={!!customer} label='account' />
					</button>
				</Link>
			)}
		</>
	);
};

export default AccountButton;
