import { getError } from '@services/handle-fetch-response';

export const sendPupillaryDistance = async (orderNumber: string, pd: string | number) => {
	try {
		const res = await fetch(`${process.env.NEXT_PUBLIC_POMS_URL}/api/pd_submission`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ orderno: orderNumber, pd }),
		});

		return res.ok;
	} catch (error) {
		throw getError(
			[
				{
					message: `${error} \n Most likely related to an unexpected output. e.g POMS might be protected with password or not available.`,
				},
			],
			500
		);
	}
};
