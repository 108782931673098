import { FC, forwardRef } from 'react';
import { TypographyProps } from '@ts/components';

const defaultStyle = {
	fontFamily: 'var(--platform)',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '1.8rem',
	lineHeight: '2.2rem',
	letterSpacing: '0.0018em',
};

/**
 * Title
 *
 * @default
 * - Size: 1.8rem
 * - Weight: 300
 * - Height: 2.2rem
 * - Spacing: 0.0018em
 * - Family: var(--platform)
 */
const Title: FC<TypographyProps<HTMLParagraphElement>> = forwardRef(({ children, style, ...props }, forwardedRef) => {
	return (
		<p
			style={{
				...defaultStyle,
				...style,
			}}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</p>
	);
});

Title.displayName = 'Title';

export default Title;
