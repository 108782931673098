import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type SearchIconProps = {
	label: string;
};

const SearchIcon = ({ label }: SearchIconProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
				<path
					d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z'
					stroke='#141414'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M13 13L17 17' stroke='#141414' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default SearchIcon;
