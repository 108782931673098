const customerAccessTokenDeleteMutation = /* GraphQL */ `
	mutation customerAccessTokenDelete($customerAccessToken: String!) {
		customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
			deletedAccessToken
			deletedCustomerAccessTokenId
			userErrors {
				field
				message
			}
		}
	}
`;

export default customerAccessTokenDeleteMutation;
