const getProductVariantQuery = /* GraphQL */ `
	query getVariantById($id: ID!) {
		node(id: $id) {
			... on ProductVariant {
				title
				product {
					handle
				}
			}
		}
	}
`;

export default getProductVariantQuery;
