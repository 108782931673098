import { Viewport } from '@radix-ui/react-toast';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { useHasMounted } from '@utils/hooks';
import styles from './Toast.module.scss';

const ToastViewport = () => {
	const hasMounted = useHasMounted();

	// Portal the Toast outside of the div#__next container so that it can be positioned above other modal-like components
	return hasMounted ? (
		<PortalRoot asChild>
			<Viewport className={styles.viewport} />
		</PortalRoot>
	) : null;
};

export default ToastViewport;
