import { useRouter } from 'next/router';
import { Button, Flex, Heading, Modal, Paragraph } from '@components';
import { buildUrl } from '@utils/urls';
import styles from './LoginPrompt.module.scss';

const LoginPrompt = ({ returnUrl }: { returnUrl?: string }) => {
	const loginURL = buildUrl('account/login', { returnUrl });
	const router = useRouter();
	return (
		<div className={styles.container}>
			<div>
				<Heading tag='h5'>Log in to Favorite items</Heading>
				<Paragraph>
					You can save your favorite items when you log in to your Pair Eyewear account. Get started today!
				</Paragraph>
			</div>

			<Flex gap={3} className={styles.buttonsContainer}>
				<Modal.Close asChild>
					<Button color='white'>Cancel</Button>
				</Modal.Close>
				<Modal.Close asChild>
					<Button
						fullWidth
						onClick={() => {
							router.push(loginURL);
						}}
						type='button'
					>
						Log in
					</Button>
				</Modal.Close>
			</Flex>

			<Flex justify='center' className={styles.footer}>
				<Paragraph>Don’t have an account?</Paragraph>
				<Button href='/account/register' linkStyle size='small' label='Sign Up' />
			</Flex>
		</div>
	);
};

export default LoginPrompt;
