import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const MobileNavClose = ({ label }) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M21.6568 21.6568L10.3431 10.3431'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='square'
					strokeLinejoin='round'
				/>
				<path d='M21.3137 10L10 21.3137' stroke='black' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path d='M10 10L21.3137 21.3137' stroke='black' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default MobileNavClose;
