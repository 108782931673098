import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { Mutation, MutationCartAttributesUpdateArgs } from '@ts/shopify-storefront-api';
import { cartAttributesUpdateMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';

const useCartAttributesUpdate = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { data: cartId } = useCartId();

	return useMutation(
		['cart', 'attributesUpdate', { cartId }],
		async ({ attributes }: MutationCartAttributesUpdateArgs) => {
			if (!cartId) return;
			const { cartAttributesUpdate }: Mutation = await fetchStorefrontApi(cartAttributesUpdateMutation, {
				variables: { cartId, attributes },
			});
			const { userErrors } = cartAttributesUpdate;

			throwCartErrors(userErrors);
			queryClient.invalidateQueries(['cart', { cartId }]);
		},
		{
			onError: error => {
				console.error(`Error updating attributes in cart ${cartId}: ${error}`);
				queryClient.invalidateQueries(['cart', cartId]);
			},
		}
	);
};

export { useCartAttributesUpdate };
