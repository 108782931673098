import Link from 'next/link';
import cn from 'classnames';
import styles from './ConditionalLink.module.scss';

type ConditionalLinkProps = {
	children: React.ReactNode;
	href: string;
	condition: boolean;
	title?: string;
	className?: string;
	style?: React.CSSProperties;
	reduceOpacityOnHover?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ConditionalLink = ({
	children,
	className,
	style,
	href,
	condition,
	reduceOpacityOnHover = false,
	...props
}: ConditionalLinkProps) => {
	const classes = cn(className, {
		[styles.reduceOpacityOnHover]: reduceOpacityOnHover,
	});

	return !!condition && href ? (
		<Link href={href} className={classes} style={style} {...props}>
			{children}
		</Link>
	) : (
		<div className={className} style={style} data-condition-link={condition}>
			{children}
		</div>
	);
};

export default ConditionalLink;
