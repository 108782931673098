export type ErrorData = {
	message: string;
	code?: string;
};

export type ErrorProps = {
	code?: string;
} & ({ message: string; errors?: never } | { message?: never; errors: ErrorData[] });

export class PairError extends Error {
	errors: ErrorData[];
	code?: string;
	constructor({ message, code, errors }: ErrorProps) {
		const error: ErrorData = message ? { message, ...(code ? { code } : {}) } : errors![0];

		super(error.message);
		this.errors = message ? [error] : errors!;

		if (error.code) this.code = error.code;
	}
}

export class FetcherError extends PairError {
	status: number;
	constructor(
		options: {
			status: number;
		} & ErrorProps
	) {
		super(options);
		this.status = options.status;
	}
}

export class ValidationError extends PairError {
	constructor(options: ErrorProps) {
		super(options);
		this.code = 'validation_error';
	}
}
