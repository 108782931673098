import { LOCALE_DICT, PRODUCT_TYPES } from '@constants';
import { CurrencyCode } from '@ts/shopify-storefront-api';

type list = {
	description: string;
	checkmark: boolean;
	tooltip?: string;
}[];

export function getPurchaseChecklist(locale: string, threshold: number): list {
	const currency = LOCALE_DICT[locale].currency;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const checklist = [
		{
			description: !!threshold
				? `Free Standard Shipping On Orders ${currency}${threshold}+`
				: `Free Standard Shipping on All ${countryCode} Orders`,
			checkmark: true,
		},
		{
			description: '30-Day Trial With Free Returns',
			checkmark: true,
		},
		{
			description: 'FSA and HSA Eligible or Insurance Reimbursement',
			checkmark: true,
			tooltip: 'All refunds will be issued to the original payment method — no exceptions',
		},
	];

	if (locale === 'en-GB' || locale === 'en-AU') {
		checklist.pop();
	}

	return checklist;
}

export const emptyUpsellProduct = {
	availableForSale: true,
	totalInventory: 1,
	id: null,
	path: null,
	slug: null,
	price: { amount: 0, currencyCode: CurrencyCode.Usd },
	type: PRODUCT_TYPES.LENS,
	images: [],
	variants: [],
	options: [],
	tags: [],
};

export const emptyUpsellLine = {
	id: null,
	variant: null,
	properties: null,
	discountAllocations: null,
	quantity: 1,
};
