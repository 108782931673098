import aa from 'search-insights';
import { useQueryClient, useMutation, UseMutationResult } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createFavorite } from '@services/poms';
import { LOCALE_DICT, TOAST } from '@constants';
import { useToastContext } from '@context';
import { Favorites, useFavoritesMutation } from '@ts/poms';
import { updateFavoritePrice } from '@utils/poms';
import { getProductVariantsPrice } from '@services/shopify';
import { ALGOLIA_SEARCH_INDEXES } from '@utils/algolia';

const useFavoritesAdd = (customerId: string): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	return useMutation(
		async ({ productId, variantId }: useFavoritesMutation) => {
			let data = await createFavorite(customerId, productId, variantId);

			if (locale !== 'en-US') {
				const ids = data.variant.admin_graphql_api_id;
				const shopifyProducts = await getProductVariantsPrice([ids], locale);
				data = updateFavoritePrice(data, shopifyProducts);
			}

			const oldFavorites: Favorites = queryClient.getQueryData(['favorites', customerId, locale]);
			const newFavorites = new Set([...oldFavorites, data]);
			newFavorites.forEach(favorite => {
				if (!favorite.id) {
					newFavorites.delete(favorite);
				}
			});
			queryClient.setQueryData(['favorites', customerId, locale], Array.from(newFavorites));

			aa('sendEvents', [
				{
					eventType: 'click',
					eventName: 'Favorite Added',
					userToken: customerId,
					index: ALGOLIA_SEARCH_INDEXES[countryCode].SHOPIFY_PRODUCTS,
					objectIDs: [data?.product?.shopify_id, data?.variant?.shopify_id],
				},
			]);
		},
		{
			onError: error => {
				queryClient.invalidateQueries(['favorites', customerId, locale]);
				console.error(error);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
		}
	);
};

export default useFavoritesAdd;
