import React, { forwardRef, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import styles from './Input.module.scss';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	className?: string;
	onChange?: (...args: unknown[]) => unknown;
	errorMessage?: string;
	withIcon?: boolean;
	placeholderEffect?: boolean;
	required?: boolean;
	multiline?: boolean;
	mask?: string;
};

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
	className?: string;
	onChange?: (...args: unknown[]) => unknown;
	errorMessage?: string;
	withIcon?: boolean;
	required?: boolean;
	multiline?: boolean;
};

const Multiline = forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, id, placeholder, errorMessage, withIcon, required, ...rest }: TextareaProps, ref) => {
		const classes = cn(
			styles.container,
			{
				[styles['container--has-error']]: errorMessage,
				[styles['container--with-icon']]: withIcon,
			},
			className
		);

		return (
			<div className={classes}>
				<textarea
					autoCapitalize='none'
					autoComplete='off'
					autoCorrect='off'
					id={`input-${id}`}
					placeholder={placeholder}
					spellCheck='false'
					{...(rest as TextareaProps)}
					ref={ref}
				/>
				<label htmlFor={id}>
					{placeholder}
					{required && <span className={styles.asterisk}>*</span>}
				</label>
			</div>
		);
	}
);

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			id,
			placeholder,
			type,
			errorMessage,
			withIcon,
			mask,
			placeholderEffect = true,
			required,
			disabled,
			...rest
		}: InputProps,
		ref
	) => {
		const classes = cn(
			styles.container,
			{
				[styles['container--has-error']]: errorMessage,
				[styles['container--with-icon']]: withIcon,
				[styles['container--placeholder-effect']]: placeholderEffect,
				[styles['container--disabled']]: disabled,
			},
			className
		);

		const defaultProps = {
			autoCapitalize: 'none',
			autoComplete: 'off',
			autoCorrect: 'off',
			id,
			placeholder,
			spellCheck: false,
			type,
			ref,
			...rest,
		};

		return (
			<div className={classes}>
				{!!mask ? <InputMask mask={mask} {...defaultProps} /> : <input {...defaultProps} />}
				<label htmlFor={id}>
					{placeholder}
					{required && <span className={styles.asterisk}>*</span>}
				</label>
				<span className={styles['container-error-message']}>{errorMessage}</span>
			</div>
		);
	}
);

Multiline.displayName = 'Multiline';
Input.displayName = 'Input';

const InputNamespace = Object.assign(Input, { Multiline });

export default InputNamespace;
