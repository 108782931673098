import { cartErrorFragment } from '@services/shopify/fragments';

const cartAttributesUpdateMutation = /* GraphQL */ `
	mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
		cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
			cart {
				id
			}
			userErrors {
				...cartErrorFragment
			}
		}
	}
	${cartErrorFragment}
`;

export { cartAttributesUpdateMutation };
