export function alphabetize(arr: string[], shouldReverse = false) {
	return arr.sort((a, b) => (shouldReverse ? b.localeCompare(a) : a.localeCompare(b)));
}

export function swap(arr: any[], indexA: number, indexB: number) {
	const newArr = [...arr];
	const temp = newArr[indexA];
	newArr[indexA] = newArr[indexB];
	newArr[indexB] = temp;

	return newArr;
}
