import { forwardRef } from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type CheckmarkProps = {
	className?: string;
	color?: string;
	fillColor?: string;
	thick?: boolean;
	circle?: boolean;
	width?: number | string;
	height?: number | string;
};

const Checkmark = forwardRef<SVGSVGElement, CheckmarkProps>(
	({ className = '', color = '#fff', fillColor = 'none', thick = false, circle = false, width = 11, height = 9 }, ref) => {
		if (thick) {
			return (
				<AccessibleIcon.Root label='checkmark'>
					<svg
						className={className}
						width={width}
						height={height}
						viewBox='0 0 11 9'
						fill={fillColor}
						xmlns='http://www.w3.org/2000/svg'
						ref={ref}
						style={{ flexShrink: 0 }}
					>
						<path d='M0.831055 4L3.83105 7L9.83105 1' stroke={color} strokeWidth='2' />
					</svg>
				</AccessibleIcon.Root>
			);
		}

		if (circle) {
			return (
				<AccessibleIcon.Root label='checkmark'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className={className}
						width={width}
						height={height}
						viewBox='0 0 24 24'
						fill={fillColor}
					>
						<circle cx='12' cy='12' r='9' stroke={color} strokeWidth='2' />
						<path d='M8.00011 11.682L10.8285 14.5104L16.5 8.81812' stroke={color} strokeWidth='2' />
					</svg>
				</AccessibleIcon.Root>
			);
		}

		return (
			<AccessibleIcon.Root label='checkmark'>
				<svg
					className={className}
					width={width}
					height={height}
					viewBox='0 0 11 9'
					fill={fillColor}
					xmlns='http://www.w3.org/2000/svg'
					ref={ref}
					style={{ flexShrink: 0 }}
				>
					<path d='M0.636039 4.36396L3.46447 7.19239L9.82843 0.828427' stroke={color} strokeWidth='1.5' />
				</svg>
			</AccessibleIcon.Root>
		);
	}
);

Checkmark.displayName = 'Checkmark';

export default Checkmark;
