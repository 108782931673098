/***------ Contentful ------***/
export const CONTENTFUL_ANNOUNCEMENT_BAR_NAME = 'announcement-bar-2022';
export const CONTENTFUL_MENU_NAME = 'main-navigation-2022';
export const CONTENTFUL_TAG_LOGGEDIN = 'loggedIn';
export const CONTENTFUL_TAG_LOGGEDOUT = 'loggedOut';

export const CONTENTFUL_BUTTON_EVENTS = {
	ATTENTIVE: 'Attentive',
	NONE: 'None',
	VTO: 'VTO',
};

export const COLLECTION_LISTS = {
	ACTIVE: 'active-collections',
	BUILD_FLOW: 'build-flow-collections',
	EVERGREEN: 'evergreen-collection-list',
	SNEAK_PEEK: 'sneak-peek-collections',
	LEAVING_SOON: 'leaving-soon-collection',
	PRIORITY: 'ssg-collections',
};

export const CONTENT_TYPES = {
	ANNOUNCEMENT_BAR: 'announcement-bar-2022',
	CAROUSEL_HERO: 'homepageHeroCarousel',
	CAROUSEL_TESTIMONIAL: 'testimonialSlider',
	CAROUSEL_TILES: 'componentCollectionCarousel__tiles',
	CAROUSEL_TOPS_PREVIEW: 'componentCollectionCarousel__topsPreview',
	COUNTDOWN_BANNER: 'componentCountdownBanner',
	FAQ: 'frequentlyAskedQuestions',
	FULL_WIDTH_TEXT_SECTION: 'componentFullWidthTextSection',
	HERO: 'componentHero',
	HOW_IT_WORKS: 'howItWorks',
	LOGGED_IN_ACCOUNT_CARD: 'componentLoggedAccountCard',
	MARKETING_BLOCKS: 'marketingBlocks',
	MENUS: 'menus',
	NEWSLETTER: 'newsletter',
	PAGES: 'pages',
	TWO_PANEL_CONTENT_CARD: 'componentTwoPanelContentCard',
	VALUE_PROPOSITION: 'componentValueProposition',
};

/***------ Landing Page Routing ------***/
export const PAGE_SLUG_EXCEPTIONS = ['blue-light', 'sunglasses', '/', 'about', 'talent', 'ra-test'];
