import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createFavorite, getFavoritesByCustomerId } from '@services/poms';
import { Favorites } from '@ts/poms';
import { getProductVariantsPrice } from '@services/shopify';
import { updateFavoritePrice } from '@utils/poms';

const useFavorites = (customerId: string): UseQueryResult<Favorites> => {
	const { locale } = useRouter();

	return useQuery<Favorites>(
		['favorites', customerId, locale],
		async () => {
			// Check if there is a favoriteLogOut in local storage and create it
			const favoriteLogOut = JSON.parse(window.localStorage.getItem('favoriteLogOut'));
			if (favoriteLogOut?.productId && favoriteLogOut?.variantId) {
				await createFavorite(customerId, favoriteLogOut.productId, favoriteLogOut.variantId);
				window.localStorage.removeItem('favoriteLogOut');
			}
			const data = await getFavoritesByCustomerId(customerId);

			let favorites = data as Favorites;
			if (data?.metafield) {
				favorites = data.metafield as Favorites;
			}
			if (locale !== 'en-US') {
				const ids = [];
				favorites.forEach(favorite => {
					const id = favorite.variant?.admin_graphql_api_id;
					if (id) ids.push(id);
				});
				const shopifyProducts = await getProductVariantsPrice(ids, locale);
				const updatedPrice = favorites.map(favorite => {
					return updateFavoritePrice(favorite, shopifyProducts);
				});

				return updatedPrice;
			}

			return favorites;
		},
		{
			placeholderData: [],
			enabled: customerId !== undefined && Boolean(customerId),
			retry: false,
			refetchOnWindowFocus: false,
			staleTime: Number.POSITIVE_INFINITY,
		}
	);
};

export default useFavorites;
