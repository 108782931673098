import React, { forwardRef } from 'react';
import { default as NextLink } from 'next/link';
import cn from 'classnames';
import { ComponentProps } from '@ts/components';
import styles from './Link.module.scss';

type LinkProps = ComponentProps<HTMLAnchorElement> & {
	href: string;
	label?: string;
	openNewTab?: boolean;
	fullOpacity?: boolean;
	small?: boolean;
	title?: string;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
	({ children, className, href, openNewTab = false, title = '', small = false, fullOpacity = false, ...rest }, ref) => {
		const classes = cn(styles['link'], className, {
			[styles['link--small']]: small,
			[styles['full-opacity']]: fullOpacity,
		});
		return (
			<NextLink
				href={href}
				title={title}
				className={classes}
				ref={ref}
				{...(openNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
				{...rest}
			>
				{children}
			</NextLink>
		);
	}
);

Link.displayName = 'Link';

export default Link;
