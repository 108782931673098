import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

interface UKFlagProps {
	width?: number;
	height?: number;
}

const UKFlag = ({ width = 32, height = 32 }: UKFlagProps) => {
	return (
		<AccessibleIcon.Root label='UKFlag'>
			<svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_16724_44533)'>
					<path
						d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
						fill='#F0F0F0'
					/>
					<path
						d='M3.30758 6.25891C2.05077 7.8941 1.10308 9.77879 0.55127 11.8262H8.87489L3.30758 6.25891Z'
						fill='#0052B4'
					/>
					<path
						d='M31.449 11.8262C30.8972 9.77885 29.9494 7.89416 28.6927 6.25897L23.1255 11.8262H31.449Z'
						fill='#0052B4'
					/>
					<path
						d='M0.55127 20.174C1.10314 22.2214 2.05083 24.1061 3.30758 25.7412L8.87471 20.174H0.55127Z'
						fill='#0052B4'
					/>
					<path
						d='M25.7411 3.30758C24.1059 2.05077 22.2213 1.10308 20.1738 0.551208V8.87477L25.7411 3.30758Z'
						fill='#0052B4'
					/>
					<path
						d='M6.25879 28.6924C7.89398 29.9492 9.77866 30.8969 11.826 31.4488V23.1253L6.25879 28.6924Z'
						fill='#0052B4'
					/>
					<path
						d='M11.826 0.551208C9.7786 1.10308 7.89391 2.05077 6.25879 3.30752L11.826 8.87471V0.551208Z'
						fill='#0052B4'
					/>
					<path
						d='M20.1738 31.4488C22.2212 30.8969 24.1059 29.9492 25.741 28.6925L20.1738 23.1253V31.4488Z'
						fill='#0052B4'
					/>
					<path
						d='M23.1255 20.174L28.6927 25.7413C29.9494 24.1061 30.8972 22.2214 31.449 20.174H23.1255Z'
						fill='#0052B4'
					/>
					<path
						d='M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2926 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2926 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z'
						fill='#D80027'
					/>
					<path
						d='M20.1738 20.174L27.3136 27.3138C27.642 26.9855 27.9552 26.6423 28.2541 26.2866L22.1415 20.174H20.1738V20.174Z'
						fill='#D80027'
					/>
					<path
						d='M11.8258 20.174H11.8257L4.68604 27.3137C5.01429 27.6421 5.35747 27.9553 5.71322 28.2542L11.8258 22.1414V20.174Z'
						fill='#D80027'
					/>
					<path
						d='M11.8259 11.8262V11.8261L4.68611 4.68628C4.35773 5.01453 4.04448 5.35772 3.74561 5.71347L9.85829 11.8262H11.8259V11.8262Z'
						fill='#D80027'
					/>
					<path
						d='M20.1738 11.8262L27.3136 4.68629C26.9854 4.35791 26.6422 4.04466 26.2865 3.74585L20.1738 9.85854V11.8262Z'
						fill='#D80027'
					/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default UKFlag;
