const customerQuery = /* GraphQL */ `
	query getCustomer($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			id
			firstName
			lastName
			displayName
			defaultAddress {
				address1
				address2
				city
				country
				phone
				province
				zip
			}
			email
			phone
			tags
			acceptsMarketing
			createdAt
			metafield(namespace: "custom", key: "recent_checkout") {
				id
				value
			}
		}
	}
`;

export default customerQuery;
