export const hexToRgba = (hex: string, a = 1): string | null => {
	const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
	const match = hex.match(regex);

	if (!match) {
		return null;
	}

	const r = parseInt(match[1], 16);
	const g = parseInt(match[2], 16);
	const b = parseInt(match[3], 16);

	return `rgba(${r},${g},${b},${a})`;
};
