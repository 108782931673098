import aa from 'search-insights';
import { useQueryClient, useMutation, UseMutationResult } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { deleteFavorite } from '@services/poms';
import { LOCALE_DICT, TOAST } from '@constants';
import { useToastContext } from '@context';
import { Favorites, useFavoritesMutation } from '@ts/poms';
import { ALGOLIA_SEARCH_INDEXES } from '@utils/algolia';

const useFavoritesRemove = (customerId: string): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	return useMutation(
		async ({ productId, variantId }: useFavoritesMutation) => {
			const data = await deleteFavorite(customerId, productId, variantId);

			const oldFavorites: Favorites = queryClient.getQueryData(['favorites', customerId, locale]);
			const newFavorites = new Set(oldFavorites);
			newFavorites.forEach(fav => {
				if (fav.id === data.id) {
					newFavorites.delete(fav);
				}
			});
			queryClient.setQueryData(['favorites', customerId, locale], Array.from(newFavorites));

			aa('sendEvents', [
				{
					eventType: 'click',
					eventName: 'Favorite Removed',
					userToken: customerId,
					index: ALGOLIA_SEARCH_INDEXES[countryCode].SHOPIFY_PRODUCTS,
					objectIDs: [data.product.shopify_id, data.variant.shopify_id],
				},
			]);
		},
		{
			onError: error => {
				queryClient.invalidateQueries(['favorites', customerId, locale]);
				console.error(error);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
		}
	);
};

export default useFavoritesRemove;
