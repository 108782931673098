import { FC, ForwardedRef, forwardRef, ReactNode } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import variables from '@styles/export.module.scss';
import styles from './Lozenge.module.scss';

export interface LozengeProps {
	alignment?: 'left' | 'center' | 'right';
	shape?: 'pill' | 'square';
	children?: ReactNode;
	text?: string;
	extraClasses?: string;
	url?: string;
	backgroundColor?: string;
	color?: string;
	displayText?: string;
	size?: 'small' | 'regular';
	ref?: ForwardedRef<HTMLDivElement>;
	image?: string;
	emoji?: string;
}

const Lozenge: FC<LozengeProps> = forwardRef(
	(
		{
			alignment,
			shape = 'pill',
			size = 'small',
			children,
			extraClasses,
			url,
			backgroundColor = variables.blue2,
			color = variables.gray0,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			displayText,
			text = undefined,
			...rest
		},
		ref
	) => {
		const classes = cn(styles['lozenge'], extraClasses, {
			[styles['lozenge--pill']]: shape === 'pill',
			[styles['lozenge--square']]: shape === 'square',
			[styles['lozenge--left']]: alignment === 'left',
			[styles['lozenge--center']]: alignment === 'center',
			[styles['lozenge--right']]: alignment === 'right',
			[styles['lozenge--regular']]: size === 'regular',
		});

		return (
			<div className={classes} style={{ backgroundColor, color }} ref={ref} {...rest}>
				{url ? (
					<Link href={url} className={styles['title']}>
						{text || children}
					</Link>
				) : (
					text || children
				)}
			</div>
		);
	}
);

Lozenge.displayName = 'Lozenge';

export default Lozenge;
