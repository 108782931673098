import { Favorite } from '@ts/poms';

export const createFavorite = async (customerId: string, productId?: string, variantId?: string): Promise<Favorite> => {
	try {
		const url = new URL(`${process.env.NEXT_PUBLIC_POMS_URL}/api/favorites/create`);

		url.searchParams.set('customer_id', customerId);
		url.searchParams.set('api_key', process.env.NEXT_PUBLIC_POMS_API_KEY);

		if (productId) url.searchParams.set('product_id', productId);
		if (variantId) url.searchParams.set('variant_id', variantId);

		const response = await fetch(url.toString(), {
			method: 'POST',
			headers: {
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
			},
		});
		if (response.status !== 200) throw new Error(`HTTP Response ${response.status}: Create Favorite Failed`);

		return await response.json();
	} catch (error) {
		throw error;
	}
};

export const getFavoritesByCustomerId = async (customerId: string) => {
	try {
		if (!customerId) throw new Error(`Get Favorites Error: Customer id is required`);

		const url = new URL(`${process.env.NEXT_PUBLIC_POMS_URL}/api/favorites/list`);

		url.searchParams.set('customer_id', customerId);
		url.searchParams.set('api_key', process.env.NEXT_PUBLIC_POMS_API_KEY);

		const response = await fetch(url.toString(), {
			method: 'GET',
			headers: {
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
			},
		});

		if (response.status !== 200) throw new Error(`HTTP Response ${response.status}: Get Favorites Failed`);

		return await response.json();
	} catch (error) {
		throw error;
	}
};

export const deleteFavorite = async (customerId: string, productId?: string, variantId?: string): Promise<Favorite> => {
	try {
		const url = new URL(`${process.env.NEXT_PUBLIC_POMS_URL}/api/favorites/delete`);

		url.searchParams.set('customer_id', customerId);
		url.searchParams.set('api_key', process.env.NEXT_PUBLIC_POMS_API_KEY);

		if (productId) url.searchParams.set('product_id', productId);
		if (variantId) url.searchParams.set('variant_id', variantId);

		const response = await fetch(url.toString(), {
			method: 'DELETE',
			headers: {
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
			},
		});

		if (response.status !== 200) throw new Error(`HTTP Response ${response.status}: Delete Favorite Failed`);

		return await response.json();
	} catch (error) {
		throw error;
	}
};
