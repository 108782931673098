import { SVGAttributes } from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import variables from '@styles/export.module.scss';

const { blue2 } = variables;

type InformationIconProps = SVGAttributes<SVGSVGElement> & {
	width?: number | string;
	height?: number | string;
	className?: string;
	color?: string;
};

const InformationIcon = ({ width = 20, height = 20, className, color = blue2 }: InformationIconProps) => {
	return (
		<AccessibleIcon.Root label='information'>
			<svg
				className={className}
				width={width}
				height={height}
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='10' cy='10' r='9.25' stroke={color} strokeWidth='1.5' />
				<path d='M9 14.5V7.92557H11V14.5H9Z' fill={color} />
				<circle cx='10' cy='6' r='1' fill={color} />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default InformationIcon;
