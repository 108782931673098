import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type ChevronProps = {
	direction?: 'up' | 'down' | 'right' | 'left';
	reversed?: boolean;
	width?: number;
	height?: number;
	color?: string;
	extraClasses?: string;
	extraStyles?: object;
};

const Chevron = ({
	direction = 'down',
	reversed = false,
	width = 16,
	height = 16,
	color = 'inherit',
	extraClasses = '',
	extraStyles = {},
}: ChevronProps) => {
	const arrowDirection = {
		up: '180deg',
		down: '0deg',
		right: '270deg',
		left: '90deg',
	};

	const arrowReversedDirection = {
		up: '0deg',
		down: '180deg',
		right: '90deg',
		left: '270deg',
	};

	const determineRotation = () => {
		let directionSource = arrowDirection;
		if (reversed) directionSource = arrowReversedDirection;

		return directionSource[direction] ?? directionSource.down;
	};

	const svgStyle = {
		transform: `rotate(${determineRotation()})`,
		transition: 'all 200ms ease',
		color: color,
		flexShrink: 0,
		...extraStyles,
	};

	return (
		<AccessibleIcon.Root label={`${direction} arrow`}>
			<svg
				className={`${extraClasses}`}
				width={width}
				height={height}
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				stroke='currentColor'
				strokeWidth={1}
				style={svgStyle}
			>
				<path
					d='M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z'
					fill='currentColor'
					fillRule='evenodd'
					clipRule='evenodd'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Chevron;
