import { CardProps } from '@ts/index';
import Card from '../Card/Card';

const HorizontalCard = ({
	buttonGroupType = 'side-by-side',
	contentType = 'vertical',
	containerType = 'horizontal',
	product,
	primaryAction = 'cart',
	showDescription = true,
	showCollectionLozenge,
	variant,
	...rest
}: Omit<CardProps, 'children'>) => {
	return (
		<Card
			containerType={containerType}
			contentType={contentType}
			showDescription={showDescription}
			buttonGroupType={buttonGroupType}
			product={product}
			variant={variant}
			showCollectionLozenge={showCollectionLozenge}
			primaryAction={primaryAction}
			{...rest}
		/>
	);
};

export default HorizontalCard;
