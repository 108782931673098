import Link from 'next/link';
import { useRouter } from 'next/router';
import { ALL_TOPS_WIDGET_COPY } from '@constants';
import { Button, Container, CutPaper, Heading, Img, TypographyButton } from '@components';
import createCutPaperPath from '@utils/create-cut-paper';
import variables from '@styles/export.module.scss';
import styles from './AllTopsWidget.module.scss';

const AllTopsWidget = () => {
	const path = createCutPaperPath('papaya');
	const { locale } = useRouter();
	return (
		<Container className={styles.container}>
			<Link href={ALL_TOPS_WIDGET_COPY.CTA_LINK} role='none' data-handle='all-tops-widget'>
				<div className={styles.heading}>
					<Heading tag='h4' className={styles.title}>
						{ALL_TOPS_WIDGET_COPY.TITLE}
					</Heading>
					<CutPaper padding='0.5rem' cutPaperPath={path} backgroundColor={variables.blue1} textColor='white'>
						<TypographyButton>{ALL_TOPS_WIDGET_COPY.HEADING[locale]}</TypographyButton>
					</CutPaper>
				</div>
				<Button color='white'>{ALL_TOPS_WIDGET_COPY.CTA_TEXT}</Button>
				<Img src={ALL_TOPS_WIDGET_COPY.IMAGE_URL} alt='All Tops' aspectRatio={'4/3'} height={455} width={254} />
			</Link>
		</Container>
	);
};

export default AllTopsWidget;
