import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Flex, Loading, TypographyButton } from '@components';
import { LOCALE_CODES, LOCALE_DICT, formatCurrency } from '@utils/index';
import { ComponentProps } from '@ts/index';
import { useHasMounted } from '@utils/hooks';
import KlarnaPlacement from '../KlarnaPlacement';
import styles from './Subtotal.module.scss';

type SubtotalProps = ComponentProps<HTMLDivElement> & {
	subtotal: number | string;
	isLoading?: boolean;
	topSelectedNumber?: number;
};

const Subtotal = ({ subtotal, isLoading = false, topSelectedNumber }: SubtotalProps) => {
	const { locale } = useRouter();
	const isMounted = useHasMounted();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const formattedSubtotal = useMemo(() => {
		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		if (typeof subtotal === 'string') return subtotal;
		return formatCurrency(
			{
				amount: subtotal,
				minDigits: 2,
				maxDigits: 2,
				currencyCode,
				locale,
			},
			showCurr
		);
	}, [subtotal, currencyCode, locale]);

	if (!isMounted) return;

	return (
		<div className={styles['subtotal-container']}>
			<Flex align='baseline'>
				{!topSelectedNumber ? (
					<TypographyButton
						data-test-subtotal={subtotal}
						className={styles['subtotal']}
						style={{ marginLeft: '0.5ch' }}
						small
					>
						{!isLoading && formattedSubtotal}
						{isLoading && <Loading removeDefaultStyling className={styles.loading} small />}
					</TypographyButton>
				) : (
					<TypographyButton className={styles['subtotal']} style={{ marginLeft: '0.5ch' }} small>
						{!isLoading && `${topSelectedNumber} item${topSelectedNumber > 1 ? 's' : ''} added`}
						{isLoading && <Loading removeDefaultStyling className={styles.loading} small />}
					</TypographyButton>
				)}
			</Flex>
			<KlarnaPlacement subtotal={typeof subtotal === 'number' && subtotal} />
		</div>
	);
};

export default Subtotal;
