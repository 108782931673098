import handleFetchResponse from '@services/handle-fetch-response';

type PhotoUploadArgs = {
	file: File;
	key: string;
	checkout_token?: string;
};

export const sendPhoto = async ({ file, key, checkout_token }: PhotoUploadArgs) => {
	const payload = new FormData();
	payload.append('file', file);
	payload.append('key', key);
	checkout_token && payload.append('checkout_token', checkout_token);
	try {
		const res = await fetch(`${process.env.NEXT_PUBLIC_POMS_URL}/api/prescription/upload`, {
			method: 'POST',
			body: payload,
		});
		return await handleFetchResponse(res);
	} catch (error) {
		console.error(`Error sending photo to POMS: ${error}`);
		throw error;
	}
};
