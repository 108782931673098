import { useState, useEffect } from 'react';

/*
 * This hook is used to get the window dimensions.
 * For SSR, it returns the default values.
 *
 * Note: This hook could be affected by the browser.
 * For example, for Safari the window dimensions are not accurate.
 * Safari takes the width of the scroll bar into account, while Chrome does not.
 */
function getWindowDimensions() {
	try {
		const {
			visualViewport: { width, height },
		} = window;
		return {
			width,
			height,
		};
	} catch (error) {
		return {
			width: 0,
			height: 0,
		};
	}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowDimensions;
}
