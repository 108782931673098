const thirtyDaysAgo = new Date();
const yearAgo = new Date();

thirtyDaysAgo.setMonth(thirtyDaysAgo.getMonth() - 1);
thirtyDaysAgo.setHours(0, 0, 0, 0);
const [dmonth, dday, dyear] = thirtyDaysAgo.toLocaleDateString().split('/');
const maxFilter = dyear + '-' + dmonth + '-' + dday;

yearAgo.setFullYear(yearAgo.getFullYear() - 1);
yearAgo.setHours(0, 0, 0, 0);
const [ymonth, yday, yyear] = yearAgo.toLocaleDateString().split('/');
const minFilter = yyear + '-' + ymonth + '-' + yday;

const bfroDiscountQuery = /* GraphQL */ `
	query getBfroDiscount($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			orders(first: 20, reverse: true, query: "(processed_at:>'${minFilter}', processed_at:<'${maxFilter}')") {
				edges {
					node {
						id
						name
						processedAt
						lineItems(first: 50) {
							edges {
								node {
									title
									variant {
										sku
										title
										product {
											productType
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default bfroDiscountQuery;
