import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export default function useLockedBody(locked = false) {
	useIsomorphicLayoutEffect(() => {
		if (!locked) {
			return;
		}

		const originalOverflow = document.body.style.overflow;
		const originalPaddingRight = document.body.style.paddingRight;

		// Lock body scroll
		document.body.style.overflow = 'hidden';

		// Get the scrollBar width
		const root = document.getElementById('root');
		const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

		// Avoid width reflow
		if (scrollBarWidth) {
			document.body.style.paddingRight = `${scrollBarWidth}px`;
		}

		return () => {
			document.body.style.overflow = originalOverflow;

			if (scrollBarWidth) {
				document.body.style.paddingRight = originalPaddingRight;
			}
		};
	}, [locked]);
}
