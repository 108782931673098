import React, { forwardRef } from 'react';
import cn from 'classnames';
import { ComponentProps } from '@ts/components';
import { ContainerProps, useContainerClasses } from '../Container/Container';
import styles from './Grid.module.scss';

export type GridProps = ComponentProps<HTMLDivElement> &
	ContainerProps & {
		/** Number of columns */
		columns?: number;
		centerItems?: boolean;
	};

const Grid = forwardRef<HTMLDivElement, GridProps>(
	({ children, className, columns = 1, centerItems = true, style, ...rest }, ref) => {
		const containerClasses = useContainerClasses(rest);

		const classes = cn(styles['container'], className, containerClasses, {
			[styles['container--1col']]: columns === 1,
			[styles['container--2col']]: columns === 2,
			[styles['container--3col']]: columns === 3,
			[styles['container--4col']]: columns >= 4,
			[styles['container--center']]: centerItems,
		});

		return (
			<div className={classes} ref={ref} style={style}>
				{children}
			</div>
		);
	}
);

Grid.displayName = 'Grid';

export default Grid;
