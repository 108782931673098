import { useEffect, useState } from 'react';
import { GLASSESON } from '@constants';

const initPdMeasuringTool = ({ setPdToolInitialized, setAppError, handlePdMeasuringToolResult }) => {
	const clientId = process.env.NEXT_PUBLIC_GLASSESON_CLIENT_ID;
	const apiUrl = GLASSESON.API_URL;
	const options = GLASSESON.OPTIONS;

	try {
		if (window.glasseson?.initCompleted) {
			setPdToolInitialized(true);
			setAppError(false);
			window.glasseson.setResultCallback(handlePdMeasuringToolResult);
			return;
		}

		window.glasseson.init(clientId, apiUrl, options).then(
			() => {
				window.glasseson.setResultCallback(handlePdMeasuringToolResult);
				setPdToolInitialized(true);
				setAppError(false);
			},
			error => {
				console.error(error);
				setAppError(true);
			}
		);
	} catch (error) {
		console.error('[GlassesOn SDK] Unable to init', error);
		setAppError(true);
	}
};

const useLoadGlassesOn = ({ setAppError, handlePdMeasuringToolResult, pdScriptLoaded }) => {
	const [pdToolInitialized, setPdToolInitialized] = useState(false);

	useEffect(() => {
		if (pdScriptLoaded) {
			initPdMeasuringTool({ setPdToolInitialized, setAppError, handlePdMeasuringToolResult });
		}
	}, [pdScriptLoaded]);

	return pdToolInitialized;
};

export { useLoadGlassesOn };
