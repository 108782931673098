import { GROUP_BY_DEFAULT_ATTRIBUTE } from '@constants';

/**
 * Checks if all values in the provided object are null.
 *
 * @param {Object} obj - The object to check.
 * @returns {boolean} - Returns true if all values in the object are null, otherwise false.
 */
export const isNullish = obj =>
	Object.values(obj).every(value => {
		return value === null;
	});

/**
 * Checks if all values in the provided object are falsy.
 * Falsy values are: null, undefined, NaN, 0, false, '' (empty string).
 * Note: Feel free to add more falsy values if needed.
 * @param {Object} obj - The object to check.
 * @returns {boolean} - Returns true if all values in the object are falsy, otherwise false.
 */
export const isFalsy = obj =>
	Object.values(obj).every(value => {
		return value === null || value === undefined;
	});

/**
 * Checks if the provided object is empty.
 *
 * @param {Object} obj - The object to check.
 * @returns {boolean} - Returns true if the object is empty (has no own properties), otherwise false.
 */
export const isEmpty = obj => Object.keys(obj).length === 0;

/**
 * Group items of an array of objects by attribute name
 *
 * @param arr array of items to be grouped
 * @param prop string criteria to group items. This can also have nested attributes by using dots '.'. E.g. 'variant.color'
 * @returns object
 */
export const groupBy = (arr, prop) => {
	const hash = {};
	const props = prop.split('.');
	for (let i = 0; i < arr.length; i++) {
		const key =
			props.reduce((acc, prop) => {
				return acc && acc[prop];
			}, arr[i]) ?? GROUP_BY_DEFAULT_ATTRIBUTE;
		if (!hash[key]) hash[key] = [];
		hash[key].push(arr[i]);
	}
	return hash;
};

export const removeEmptyProperties = obj => {
	const newObj = {};
	Object.keys(obj).forEach(key => {
		if (obj[key] !== null && obj[key] !== undefined && obj[key] !== 'N/A') {
			newObj[key] = obj[key];
		}
	});
	return newObj;
};

export const objectToArray = obj => {
	const keys = Object.keys(obj);
	return keys.map(key => {
		return { key: key, value: obj[key] };
	});
};
