import { CSSProperties } from 'react';

type SpacerProps = {
	size: CSSProperties['width'];
};

const Spacer = ({ size }: SpacerProps) => {
	return <div style={{ height: size, width: size, flexShrink: 0 }} />;
};

export default Spacer;
