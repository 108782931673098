import { Divider, Flex, LightBox, Lozenge, Tooltip, Trash, TypographyButton } from '@components';
import variables from '@styles/export.module.scss';
import { ButtonGroupProps } from './ButtonGroup';
import styles from './MicroButton.module.scss';

type MicroButtonProps = {
	formattedPrice: string;
	formattedCompareAtPrice?: string;
	compareAtPrice?: { amount: number; currencyCode: string };
} & Pick<
	ButtonGroupProps,
	| 'secondaryAction'
	| 'secondaryActionData'
	| 'primaryAction'
	| 'primaryActionData'
	| 'variant'
	| 'productRemoved'
	| 'isMobile'
	| 'dataTags'
>;

const MicroButton = ({
	secondaryAction,
	secondaryActionData,
	primaryAction,
	primaryActionData,
	variant,
	formattedPrice,
	formattedCompareAtPrice,
	compareAtPrice,
	productRemoved,
	isMobile,
	dataTags,
}: MicroButtonProps) => {
	return (
		<Flex justify='end' align='center' gap={3} fullWidth>
			{secondaryAction === 'zoom' && isMobile && (
				<LightBox
					iconOnly
					frameShape={secondaryActionData?.frameShape}
					variant={variant}
					width={30}
					height={28}
					dataTag={dataTags.zoom}
				/>
			)}
			{secondaryAction === 'tooltip' && (
				<>
					<Tooltip message={secondaryActionData.message} />
					<Divider alignment='vertical' color={variables.gray2} margin={'0rem'} />
				</>
			)}
			{primaryAction === 'delete' && (
				<Trash
					name={variant.removedName ?? variant?.product?.name}
					type={variant?.type}
					callback={primaryActionData?.callback}
					iconOnly
				/>
			)}
			{productRemoved ? <Lozenge>Removed</Lozenge> : null}
			<TypographyButton small {...dataTags.button}>
				<div className={styles.priceContainer}>
					<span className={styles.price} style={{ color: compareAtPrice?.amount > 0 ? variables.red1 : '' }}>
						{formattedPrice}
					</span>
					{compareAtPrice?.amount > 0 && <span className={styles['compare-price']}>{formattedCompareAtPrice}</span>}
				</div>
			</TypographyButton>
		</Flex>
	);
};

export default MicroButton;
