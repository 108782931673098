const getProductsPriceQuery = /* GraphQL */ `
	query getProductsPrice($ids: [ID!]!, $country: CountryCode = US) @inContext(country: $country) {
		nodes(ids: $ids) {
			... on ProductVariant {
				id
				sku
				price {
					amount
					currencyCode
				}
			}
		}
	}
`;
export default getProductsPriceQuery;
