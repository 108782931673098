const baseFrameVariantQuery = /* GraphQL */ `
	query baseFrameVariant($handle: String, $selectedOptions: [SelectedOptionInput!]!, $country: CountryCode = US)
	@inContext(country: $country) {
		product(handle: $handle) {
			variantBySelectedOptions(selectedOptions: $selectedOptions) {
				id
				price {
					amount
					currencyCode
				}
				compareAtPrice {
					amount
					currencyCode
				}
				selectedOptions {
					name
					value
				}
				title
			}
		}
	}
`;
export default baseFrameVariantQuery;
