import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

interface CanadaFlagProps {
	width?: number;
	height?: number;
}

const CanadaFlag = ({ width = 32, height = 32 }: CanadaFlagProps) => {
	return (
		<AccessibleIcon.Root label='CanadaFlag'>
			<svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_16625_254943)'>
					<path
						d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
						fill='#F0F0F0'
					/>
					<path
						d='M32 16C32 9.65665 28.3085 4.17565 22.9565 1.58765V30.4123C28.3085 27.8244 32 22.3434 32 16Z'
						fill='#D80027'
					/>
					<path
						d='M0 16C0 22.3434 3.69156 27.8244 9.0435 30.4124V1.58765C3.69156 4.17565 0 9.65665 0 16Z'
						fill='#D80027'
					/>
					<path
						d='M18.7824 18.0869L21.5649 16.6956L20.1737 16V14.6087L17.3911 16L18.7824 13.2174H17.3911L15.9998 11.1304L14.6084 13.2174H13.2171L14.6084 16L11.8258 14.6087V16L10.4346 16.6956L13.2171 18.0869L12.5215 19.4782H15.3041V21.5652H16.6954V19.4782H19.478L18.7824 18.0869Z'
						fill='#D80027'
					/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default CanadaFlag;
