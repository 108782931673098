import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { RX_TYPE_ID, LENS_UPGRADES } from '@constants';
import { Bundle, NormalizedCartLine } from '@ts/cart';
import { NormalizedProduct } from '@ts/product';
import { Attribute } from '@ts/shopify-storefront-api';

//-----------------------------//
//--- Collection Path Utils ---//
//-----------------------------//

export const getCollectionPathFromAttributes = (attributes: Record<string, Attribute['value']>) => {
	const customerJourney = attributes[CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_JOURNEY];
	const customerType = attributes[CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_TYPE];

	if (!customerJourney || !customerType) {
		return '';
	}

	return `/${customerJourney}/${customerType}/`;
};

/**
 * Returns the collection path from the cart line

 * @param properties
 * @returns collection path
 */
export const getCollectionPathFromCartLine = (attributes: Record<string, Attribute['value']>) => {
	return attributes?._collectionPath ?? getCollectionPathFromAttributes(attributes);
};

//----------------------//
//--- Cart API Utils ---//
//----------------------//
export function getBlueLensPrice(id: RX_TYPE_ID, lenses: Record<string, NormalizedProduct>) {
	const nonRx = id === 'non-prescription';
	const addedBlueLight = lenses[LENS_UPGRADES.BLUE_LIGHT_FILTERING];
	const numberOfLenses = Object.keys(lenses).length;

	if (nonRx && (numberOfLenses === 0 || (addedBlueLight && numberOfLenses === 1))) {
		return 0;
	}
	return 49;
}

export function getBlueLensPriceForCart({
	isBlueLensInCart,
	isNonRx,
	lensesLength,
}: {
	isBlueLensInCart: boolean;
	isNonRx: boolean;
	lensesLength: number;
}) {
	if (isNonRx) {
		if ((isBlueLensInCart && lensesLength === 1) || (!isBlueLensInCart && lensesLength === 0)) return 0;
	}
	return 49;
}

export function determinePairCarePlan(bundle: Bundle, pairCare: NormalizedProduct) {
	const bundleSubtotal = computeBundleSubtotal(bundle);
	return pairCare.variants.find(variant => {
		return (
			parseFloat(variant.metafields.minThreshold) <= bundleSubtotal &&
			parseFloat(variant.metafields.maxThreshold) >= bundleSubtotal
		);
	});
}

function checkBlueLightDiscount(
	handle: string,
	prescriptionType: string,
	existingLenses: NormalizedCartLine[],
	defaultAmount: number
) {
	// Render the discounted blue-light price if conditions are right
	const isBlueLight = handle === LENS_UPGRADES.BLUE_LIGHT_FILTERING;
	const possiblyDiscountedAmount = isBlueLight
		? getBlueLensPriceForCart({
				isBlueLensInCart: isBlueLight,
				isNonRx: prescriptionType === 'Non-Prescription',
				lensesLength: existingLenses.length,
			})
		: defaultAmount;

	return possiblyDiscountedAmount;
}

function computeBundleSubtotal(bundle: Bundle): number {
	const {
		base: { frame, lenses },
	} = bundle;
	const baseFramePrice = Number(frame.variant.price.amount);

	const lensPrice = lenses?.reduce((res, current) => {
		const prescriptionType = current.properties[CART_LINE_ATTRIBUTE_KEYS.PRESCRIPTION_TYPE];
		return (
			res +
			checkBlueLightDiscount(current.variant.product.handle, prescriptionType, lenses, Number(current.variant.price.amount))
		);
	}, 0);

	return baseFramePrice + lensPrice;
}

export function calculateCartQuantity(lines: Array<NormalizedCartLine>) {
	return lines.reduce((total, line) => total + line.quantity, 0);
}
