import { useMemo } from 'react';
import useHasMounted from './hooks/useHasMounted';

export default function checkForWKWebview() {
	if (typeof window === 'undefined') {
		// assume the worst
		return true;
	}
	const userAgent = window.navigator.userAgent;
	const isIphone = /iPhone/.test(userAgent);
	const isAndroid = /Android/.test(userAgent);
	const isInstagram = /Instagram/.test(userAgent);
	// https://developers.facebook.com/docs/sharing/best-practices
	const isFacebook = /FB_IAB\/FB4A/.test(userAgent) || /FBAN\/FBIOS/.test(userAgent);

	return (isIphone || isAndroid) && (isInstagram || isFacebook);
}

export function useIsWKWebView() {
	const hasMounted = useHasMounted();

	const isWKWebview = useMemo(() => {
		return checkForWKWebview();
	}, [hasMounted]);

	return isWKWebview;
}
