import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '@context';
import { TOAST } from '@constants';
import { normalizeCart } from '@utils/normalizers/normalize-cart';
import { Mutation } from '@ts/shopify-storefront-api';
import { cartDiscountCodeUpdateMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';

type useCartDiscountMutation = {
	cartId: string;
	discountCodes: string[];
};

const useCartDiscountUpdate = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { data: cartId } = useCartId();

	return useMutation(
		['cart', 'discount', { cartId }],
		async ({ cartId, discountCodes }: useCartDiscountMutation) => {
			const { cartDiscountCodesUpdate }: Mutation = await fetchStorefrontApi(cartDiscountCodeUpdateMutation, {
				variables: { cartId, discountCodes },
			});
			const { userErrors, cart } = cartDiscountCodesUpdate;

			throwCartErrors(userErrors);
			const normalizedCart = normalizeCart(cart);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
		},
		{
			onError: error => {
				console.error(`Error applying discount to cart ${cartId}: ${error}`);
				queryClient.invalidateQueries(['cart']);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
		}
	);
};

export { useCartDiscountUpdate };
