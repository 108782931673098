import Modal from '../Modal';
import Button from '../Button';
import FrameSizing, { FrameSizingProps } from '../FrameSizing/FrameSizing';

const FrameSizingButton = ({ frameSizings, primaryImage, name }: FrameSizingProps) => {
	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button label='Fit Guide' linkStyle size='small' data-open-fit-guide={name} />
			</Modal.Trigger>
			<Modal.Content closeButtonSticky center removePadding>
				<FrameSizing frameSizings={frameSizings} primaryImage={primaryImage} name={name} />
			</Modal.Content>
		</Modal>
	);
};

export default FrameSizingButton;
