import { cartErrorFragment } from '@services/shopify/fragments';

const cartBuyerIdentityUpdateMutation = /* GraphQL */ `
	mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
		cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
			cart {
				id
				buyerIdentity {
					customer {
						email
					}
				}
			}
			userErrors {
				...cartErrorFragment
			}
		}
	}
	${cartErrorFragment}
`;

export { cartBuyerIdentityUpdateMutation };
