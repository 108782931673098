import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import variables from '@styles/export.module.scss';

const DesktopSubNavIndicator = ({ label, rotate, style, ...rest }) => {
	const rotateStyle = rotate ? { transform: 'rotate(180deg)' } : {};
	return (
		<AccessibleIcon.Root label={label}>
			<svg
				className='soft-arrow'
				width='8'
				height='4'
				viewBox='0 0 8 4'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				style={{ ...style, rotateStyle, transition: 'transform 150ms ease-out' }}
				{...rest}
			>
				<path d='M8 0H0L4 4L8 0Z' fill={variables.blue2} />
			</svg>
		</AccessibleIcon.Root>
	);
};

export default DesktopSubNavIndicator;
