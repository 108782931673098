const baseFrameVariantsQuery = /* GraphQL */ `
	query baseFrameVariants($handle: String, $country: CountryCode = US) @inContext(country: $country) {
		product(handle: $handle) {
			variants(first: 80) {
				edges {
					node {
						title
						price {
							amount
							currencyCode
						}
					}
				}
			}
		}
	}
`;
export default baseFrameVariantsQuery;
