import { GetShopifyCollectionOptions, getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { GetProductOptions } from '@services/shopify';
import { generateQueryIdsFromContentful } from '@utils/contentful';
import { CollectionQuerySectionTypes } from '@utils/react-query';
import { CollectionListFields } from '@ts/contentful';
import { CleanedCollection } from '@ts/product';
import { fetchContentful } from '../client';

type GetCollectionListProps = {
	slug: string;
	type: CollectionQuerySectionTypes;
} & GetShopifyCollectionOptions;

export type CleanedCollectionsToSort = [CleanedCollection[], Record<number, number>];

/**
 * Multi-purpose fetching function that can return Shopify collection data from a list of Contentful handles.
 *
 * You can choose to include products and/or product images in the response and limit the number returned if products are included.
 *
 * NOTE: Normalization occurs in the React Query functions, not here.
 *
 * @defaults { type = 'default', withProducts = false, withProductImages = false }
 * @returns [collectionsMap, map]
 */
export async function getCollectionList({
	slug,
	maxProducts,
	// type = 'default',
	withProducts = false,
	skipImages = false, // in usage, this is always false?
	includeSpecificFrameVariant,
	skipVariants,
	selectedOptions = [{ name: 'Frame', value: 'Larkin' }],
	country,
}: GetCollectionListProps & GetProductOptions) {
	const list = await fetchContentful<CollectionListFields>({
		'content_type': 'collectionList',
		'fields.slug': slug,
		'limit': 1,
	});
	const [queryIds, map] = generateQueryIdsFromContentful(list);

	const collections = await getMultipleCollections(queryIds, {
		maxProducts,
		withProducts,
		skipImages,
		includeSpecificFrameVariant,
		skipVariants,
		selectedOptions,
		country,
	});

	return [collections, map] as CleanedCollectionsToSort;
}
