import React from 'react';
import { Button, Flex, Heading, Img } from '@components';
import { LENSES_WIDGET_COPY } from '@constants';
import styles from './LensesWidget.module.scss';

const LensesWidget = () => {
	const { TITLE, CTA_LINK, CTA_TEXT, IMAGE_URL } = LENSES_WIDGET_COPY;

	return (
		<div className={styles.container}>
			<Img src={IMAGE_URL} />
			<Flex column align='end' gap={4}>
				<Heading tag='h4' removeDefaultMargin>
					{TITLE}
				</Heading>
				<Button color='white' size='small' href={CTA_LINK} data-lenses-widget>
					{CTA_TEXT}
				</Button>
			</Flex>
		</div>
	);
};

export default LensesWidget;
