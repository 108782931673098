import { forwardRef } from 'react';
import cn from 'classnames';
import { ComponentProps } from '@ts/components';
import styles from './Loading.module.scss';

type LoadingProps = ComponentProps<HTMLDivElement> & {
	datatype?: string;
	removeDefaultStyling?: boolean;
	phrase?: string;
	small?: boolean;
	white?: boolean;
};

const Loading = forwardRef<HTMLDivElement, LoadingProps>(
	({ phrase, className, removeDefaultStyling = false, small = false, white = false, ...props }, ref) => {
		const classes = cn(className, {
			[styles['container']]: !removeDefaultStyling,
		});

		const innerClasses = cn(styles['inner'], {
			[styles['white']]: white,
		});

		const jumpingDotsClasses = cn(styles['jumping-dots'], {
			[styles['small']]: small,
		});

		return (
			<div className={classes} ref={ref} {...props}>
				<div className={jumpingDotsClasses}>
					<div className={styles['outer']}>
						<div className={innerClasses}></div>
					</div>
					<div className={styles['outer']}>
						<div className={innerClasses}></div>
					</div>
					<div className={styles['outer']}>
						<div className={innerClasses}></div>
					</div>
				</div>
				{phrase ? <p className={styles.phrase}>{phrase}</p> : null}
			</div>
		);
	}
);

Loading.displayName = 'Loading';

export default Loading;
