import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSiteSettings } from '@services/contentful';
import { SiteSettings } from '@ts/contentful/site-settings';

const useSiteSettings = (): UseQueryResult<SiteSettings> => {
	return useQuery(['siteSettings'], async () => {
		return await getSiteSettings();
	});
};

export default useSiteSettings;
