import { useQuery } from '@tanstack/react-query';
import { getBfroDiscount } from '@services/shopify';
import { PRODUCT_TYPES } from '@constants';
import { getCustomerToken } from '../customer-token';

const useBfroDiscount = () => {
	const customerAccessToken = getCustomerToken();

	return useQuery(
		['bfro-discount', customerAccessToken],
		async () => {
			const { orders } = await getBfroDiscount(customerAccessToken);
			const eligibleProducts: Set<string> = new Set([PRODUCT_TYPES.BASE_FRAME_SR, PRODUCT_TYPES.BASE_FRAME]);
			let isBfroDiscount = false;

			orders.forEach(order => {
				order.lineItems.forEach(item => {
					if (eligibleProducts.has(item.variant?.product?.productType)) {
						isBfroDiscount = true;
					}
				});
			});

			return isBfroDiscount;
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				if (!data) return;
			},
			onError: error => {
				console.error(error);
			},
		}
	);
};

export default useBfroDiscount;
