import { Dict } from '@ts/general';

type PaginaterProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fetcher: (query: string, { variables }: { variables: Dict }, fetchOptions: Dict) => Promise<Dict>;
	resourceName: string;
	nestedResourceName?: string;
	query: string;
	variables?: Dict;
	maxIterations?: number;
};

export default async function paginateGraphql({
	fetcher,
	resourceName,
	nestedResourceName = null,
	query,
	variables = null,
	maxIterations = 50,
}: PaginaterProps) {
	let iterations = 0;
	let reachedEnd = false;
	let paginationCursor = null;
	let parentData = {};
	let variablesWithCursor = {
		variables: { cursor: paginationCursor, ...(variables && variables) },
	};

	const resourceToPaginate = nestedResourceName ?? resourceName;

	const paginatedData = { [resourceToPaginate]: { edges: [] } };

	while (!reachedEnd) {
		const { [resourceName]: primaryResource } = await fetcher(query, variablesWithCursor, null);

		const paginatedResource = primaryResource[resourceToPaginate] ? primaryResource[resourceToPaginate] : primaryResource;

		if (!paginatedResource.edges.length) break;

		paginatedData[resourceToPaginate].edges = [...paginatedData[resourceToPaginate].edges, ...paginatedResource.edges];

		paginationCursor = paginatedResource.edges[paginatedResource.edges.length - 1].cursor;

		variablesWithCursor = {
			variables: { ...(variables && variables), cursor: paginationCursor },
		};

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { [resourceToPaginate]: omitted, ...rest } = primaryResource;

		iterations += 1;
		if (!paginatedResource.pageInfo.hasNextPage || iterations > maxIterations) {
			parentData = rest;
			reachedEnd = true;
		}
	}

	const dataToClean = nestedResourceName
		? {
				[resourceName]: {
					...parentData,
					...paginatedData,
					cursor: paginationCursor,
				},
			}
		: paginatedData;

	return dataToClean;
}
