import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Box = ({ width = 20, height = 22 }) => {
	return (
		<AccessibleIcon.Root label='box'>
			<svg width={width} height={height} viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M14.5 8.36923L5.5 3.19938M1.27 5.9387L10 10.9691L18.73 5.9387M10 21V10.9591M19 14.9436V6.97466C18.9996 6.6253 18.9071 6.28217 18.7315 5.9797C18.556 5.67723 18.3037 5.42606 18 5.25138L11 1.26691C10.696 1.09205 10.3511 1 10 1C9.64893 1 9.30404 1.09205 9 1.26691L2 5.25138C1.69626 5.42606 1.44398 5.67723 1.26846 5.9797C1.09294 6.28217 1.00036 6.6253 1 6.97466V14.9436C1.00036 15.293 1.09294 15.6361 1.26846 15.9386C1.44398 16.241 1.69626 16.4922 2 16.6669L9 20.6514C9.30404 20.8262 9.64893 20.9183 10 20.9183C10.3511 20.9183 10.696 20.8262 11 20.6514L18 16.6669C18.3037 16.4922 18.556 16.241 18.7315 15.9386C18.9071 15.6361 18.9996 15.293 19 14.9436Z'
					stroke='black'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Box;
