import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Loading } from '@components';
import variables from '@styles/export.module.scss';
import { useCartContext } from '@context';
import styles from './Trash.module.scss';

const Trash = ({ callback, name = null, type = null, iconOnly = false, dataTags = null }) => {
	const [loading, setLoading] = useState(false);
	const { isCartMutating } = useCartContext();

	const color = isCartMutating ? variables.gray2 : variables.blue2;
	const safeToClick = !loading && !isCartMutating;

	const classes = cn(styles.icon, {
		[styles.button]: !iconOnly,
	});

	useEffect(() => {
		if (!isCartMutating) {
			setLoading(false);
		}
	}, [isCartMutating]);

	return loading ? (
		<Loading removeDefaultStyling className={styles.loading} small />
	) : (
		<AccessibleIcon.Root label='Delete Item'>
			<svg
				{...(name && { 'data-remove': name })}
				{...(type && { 'data-type-remove': type })}
				{...(dataTags ? dataTags : { 'data-testid': 'trash' })}
				className={classes}
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				onClick={async () => {
					if (!safeToClick) return;
					setLoading(true);
					await callback();
				}}
				onKeyUp={async e => {
					if (e.key !== 'Enter' || !safeToClick) return;
					setLoading(true);
					await callback();
				}}
				tabIndex={0}
			>
				<rect width='32' height='32' rx='16' fill='none' />
				<path
					d='M10 11.3105L10.9613 21.9845C11.064 23.1249 12.0528 24.0002 13.2384 24.0002H18.7616C19.9472 24.0002 20.936 23.1249 21.0386 21.9845L22 11.3105'
					stroke={color}
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M12.2857 11.3103V10.4075C12.2857 9.07788 13.6502 8 15.3334 8H16.0953C17.7785 8 19.1429 9.07788 19.1429 10.4075V11.3103'
					stroke={color}
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M8 10.5605H7.25V12.0605H8V10.5605ZM24 12.0605H24.75V10.5605H24V12.0605ZM8 12.0605H24V10.5605H8V12.0605Z'
					fill={color}
				/>
				<path
					d='M8 10.5605H7.25V12.0605H8V10.5605ZM24 12.0605H24.75V10.5605H24V12.0605ZM8 12.0605H24V10.5605H8V12.0605Z'
					fill={color}
				/>
				<path
					d='M13.5357 20.4137C13.5357 20.8279 13.8715 21.1637 14.2857 21.1637C14.6999 21.1637 15.0357 20.8279 15.0357 20.4137L13.5357 20.4137ZM15.0357 14.8965C15.0357 14.4823 14.6999 14.1465 14.2857 14.1465C13.8715 14.1465 13.5357 14.4823 13.5357 14.8965L15.0357 14.8965ZM15.0357 20.4137L15.0357 14.8965L13.5357 14.8965L13.5357 20.4137L15.0357 20.4137Z'
					fill={color}
				/>
				<path
					d='M16.9642 20.4137C16.9642 20.8279 17.3 21.1637 17.7142 21.1637C18.1284 21.1637 18.4642 20.8279 18.4642 20.4137L16.9642 20.4137ZM18.4642 14.8965C18.4642 14.4823 18.1284 14.1465 17.7142 14.1465C17.3 14.1465 16.9642 14.4823 16.9642 14.8965L18.4642 14.8965ZM18.4642 20.4137L18.4642 14.8965L16.9642 14.8965L16.9642 20.4137L18.4642 20.4137Z'
					fill={color}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Trash;
