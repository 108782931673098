import { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { HeartIcon, Icon, LoginPrompt, Modal } from '@components';
import { slideUp } from '@utils/motions';
import { getShopifyIdFromGID } from '@utils/shopify';
import { useCustomer } from '@services/shopify/hooks';
import useFavorites from '@services/poms/hooks/useFavorites';
import { useFavoritesAdd, useFavoritesRemove } from '@services/poms';
import { useLocalStorage } from '@utils/hooks';
import { FavoriteIconProps } from '@ts/index';
import styles from './FavoriteIcon.module.scss';

const FavoriteIcon = ({
	product,
	variant,
	height = 18,
	width = 18,
	onClick = () => null,
	className = '',
	absolute = false,
	shape = 'square',
	dataTag,
	...props
}: FavoriteIconProps) => {
	const { asPath: currentPath } = useRouter();
	const [isActive, setIsActive] = useState(false);
	const [isBouncing, setIsBouncing] = useState(false);
	const ref = useRef(null);

	const { data: customer } = useCustomer();
	const customerId = getShopifyIdFromGID(customer?.id);
	const { data: favorites } = useFavorites(customerId);
	const { mutateAsync: favoritesAdd } = useFavoritesAdd(customerId);
	const { mutateAsync: favoritesRemove } = useFavoritesRemove(customerId);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setFavoriteLogOut] = useLocalStorage('favoriteLogOut', null);
	const variantId = getShopifyIdFromGID(variant?.id ?? '');
	const productId = product.type.includes('BASE_FRAME')
		? getShopifyIdFromGID(variant.product.id ?? '')
		: getShopifyIdFromGID(product?.id ?? '');
	const classes = cn(className, {
		[styles.absolute]: absolute,
	});

	const handleOnClick = () => {
		if (ref.current) {
			setIsBouncing(true);

			const timeout = setTimeout(() => {
				setIsBouncing(false);
				clearTimeout(timeout);
			}, 700);
		}

		if (!customer) return;

		if (isActive) {
			favoritesRemove({ productId, variantId });
		} else {
			favoritesAdd({ productId, variantId });
		}

		onClick();
	};

	useEffect(() => {
		if (customer && favorites) {
			if (!variant) {
				setIsActive(favorites.map(fav => fav.product?.shopify_id).includes(`${productId}`));
			} else {
				setIsActive(favorites.map(fav => fav.variant?.shopify_id).includes(`${variantId}`));
			}
		}
	}, [favorites, variant, customer, productId, variantId]);

	const heartIcon = (
		<HeartIcon
			active={isActive}
			isBouncing={isBouncing}
			ref={ref}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			label='favoriting button'
		/>
	);

	return !customer ? (
		<Modal>
			<Modal.Trigger
				asChild
				key='dialog-trigger'
				id={`favorite-icon-${styles['FavoriteIconNav']}`}
				onClick={() => {
					setFavoriteLogOut({ productId, variantId });
				}}
			>
				<Icon label={'Favorite Button'} className={classes} shape={shape} data-prompt-favorite={product.name} hoverable>
					{heartIcon}
				</Icon>
			</Modal.Trigger>
			<Modal.Content center customAnimation={slideUp} key='dialog-content' style={{ overflowY: 'hidden' }}>
				<LoginPrompt returnUrl={currentPath} />
			</Modal.Content>
		</Modal>
	) : (
		<>
			<Icon
				label={'Favorite Button'}
				shape='square'
				callback={handleOnClick}
				className={classes}
				{...dataTag}
				{...(isActive
					? // Dirty hack until we can standardize NormalizedProductInVariant
						{ 'data-remove-favorite': product?.name ?? product.title }
					: { 'data-add-favorite': product?.name ?? product.title })}
				{...props}
			>
				{heartIcon}
			</Icon>
		</>
	);
};

export default FavoriteIcon;
