import { useQuery } from '@tanstack/react-query';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { getCustomer } from '@services/shopify';
import { getSubscriber } from '@services/prive';
import { getCustomerToken } from '../customer-token';

const useCustomer = () => {
	const customerAccessToken = getCustomerToken();
	const growthbook = useGrowthBook();

	return useQuery(
		['customer', customerAccessToken],
		async () => {
			const customer = await getCustomer(customerAccessToken);

			// we need to make sure that "customer" is available before running any tracking
			if (!customer) {
				return null;
			}

			growthbook.setAttributes({
				...growthbook.getAttributes(),
				email: customer.email,
				loggedin: true,
			});
			const subscriberInfo = await getSubscriber(customer.id);
			customer.subscriberId = subscriberInfo?.id;
			// As soon as customer data is made available to the app, sync info with heap
			if (typeof window !== 'undefined' && window.heap) {
				window.heap.identify(customer.email);
			}
			return customer;
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				if (!data) return;
			},
		}
	);
};

export default useCustomer;
