import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { LOCALE_CODES } from '@constants';

const useSubscription = () => {
	const { locale, query } = useRouter();
	const [isSubscriptionActivated, setIsSubscriptionActivated] = useState(false);

	useEffect(() => {
		const isSubscriptionQuery = !!query?.subscriptionPath;
		const isUSLocale = locale === LOCALE_CODES.US;
		const isSubscriptionActivated = isSubscriptionQuery && isUSLocale;
		setIsSubscriptionActivated(isSubscriptionActivated);
	}, [query, locale]);

	return { isSubscriptionActivated };
};

export default useSubscription;
