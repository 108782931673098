import { productVariantFragment } from './variant';

const cartLineFragment = /* GraphQL */ `
	fragment cartLineFragment on CartLine {
		id
		quantity
		merchandise {
			...productVariantFragment
		}
		attributes {
			key
			value
		}
		cost {
			subtotalAmount {
				amount
				currencyCode
			}
			totalAmount {
				amount
				currencyCode
			}
		}
	}
	${productVariantFragment}
`;

const cartFragment = /* GraphQL */ `
	fragment cartFragment on Cart {
		id
		checkoutUrl
		lines(first: 100) {
			edges {
				node {
					...cartLineFragment
				}
			}
		}
		totalQuantity
	}
	${cartLineFragment}
`;

const cartErrorFragment = /* GraphQL */ `
	fragment cartErrorFragment on CartUserError {
		code
		field
		message
	}
`;

export { cartFragment, cartLineFragment, cartErrorFragment };
