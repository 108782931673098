import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { TypographyProps } from '@ts/components';
import styles from './TypographyButton.module.scss';

/**
 * Typography Button
 *
 * @default
 * - Size: 1.8rem
 * - Height: 2.7rem
 * - Weight: 500
 * - Spacing: 0.002em
 * - Family: var(--poppins)
 */
const TypographyButton: FC<TypographyProps<HTMLSpanElement>> = forwardRef(
	({ className, children, small = false, xsmall = false, style, ...rest }, forwardedRef) => {
		return (
			<span
				style={style}
				className={cn(styles['default'], {
					[styles['small']]: small,
					[styles['xsmall']]: xsmall,
					[className]: !!className,
				})}
				ref={forwardedRef}
				{...rest}
			>
				{children}
			</span>
		);
	}
);

TypographyButton.displayName = 'TypographyButton';

export default TypographyButton;
