// Source: https://gist.github.com/Auz/0b8c79031b09bbee0e9542c4ec250f15
export const getUUID = () => {
	const COOKIE_NAME = 'gbuuid';
	const COOKIE_DAYS = 30;

	// use the browsers crypto.randomUUID if set
	const genUUID = () => {
		if (window?.crypto?.randomUUID) return window.crypto.randomUUID();

		// using the "+" operator on a number[] and number will coerce to a string.
		// this can be unexpected, but it's useful here.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		) as string;
	};
	const getCookie = name => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	};
	const setCookie = (name, value) => {
		const d = new Date();
		d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * COOKIE_DAYS);
		document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
	};

	// get the existing UUID from cookie if set, otherwise create one and store it in the cookie
	if (getCookie(COOKIE_NAME)) return getCookie(COOKIE_NAME);

	const uuid = genUUID();
	setCookie(COOKIE_NAME, uuid);
	return uuid;
};
