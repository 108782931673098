/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS, SUBMISSION_METHODS, TOAST } from '@constants';
import variables from '@styles/export.module.scss';
import { sendDoctorInfo } from '@services/poms';
import { sendPhoto } from '@services/poms/operations/send-photo';
import { Checkmark, Flex, Modal, Paragraph, PhotoPd, PrescriptionOption, TypographyButton } from '@components';
import { SubmissionMethod } from '@ts/poms';
import { useCartContext, useToastContext } from '@utils/context';
import DoctorForm from '../DoctorForm';
import styles from './PrescriptionOptions.module.scss';
const { REMIND, PHOTO, DOCTOR } = SUBMISSION_METHODS;

const PrescriptionOptions = ({
	updateBundleSubmissionMethod,
	updatePDMeasurement,
	submissionMethodState,
	bundleKey,
	hasMeasuredPd = false,
	checkoutToken,
}: {
	updatePDMeasurement?: (newPD: number) => Promise<void>;
	updateBundleSubmissionMethod: (extraProperties?: { [k: string]: string }) => void;
	submissionMethodState: [SubmissionMethod, React.Dispatch<React.SetStateAction<SubmissionMethod>>];
	bundleKey: string;
	hasMeasuredPd?: boolean;
	checkoutToken: string;
}) => {
	const [submissionMethod, setSubmissionMethod] = submissionMethodState;
	const [photoFile, setPhotoFile] = useState<File>(null);
	const [error, setError] = useState(null);
	const [extraProperties, setExtraProperties] = useState<{ [k: string]: string }>({});
	const [modalOpen, setModalOpen] = useState(false);
	const fileRef = useRef<HTMLInputElement>();
	const isCartPDTest = useFeatureIsOn('is-cart-pd-test');
	const isNoDrTest = useFeatureIsOn('is-rx-submission-test');
	const { showToast } = useToastContext();
	const { setIsUploadingRx } = useCartContext();
	const { locale } = useRouter();

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
		if (!e.target?.files[0]?.name) return;
		setIsUploadingRx(true);
		const file = e.target.files[0];

		try {
			const extension = file.name?.match(/\.([0-9a-z]+)$/i)?.[1]?.toLowerCase();
			const isPDF = extension === 'pdf';
			const fileText = await file?.text();
			const fileSubstring = fileText?.substring(fileText?.lastIndexOf('<<'), fileText?.lastIndexOf('>>'));
			const isPDFEncrypted = isPDF ? fileSubstring?.includes('/Encrypt') : false;

			if (ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.includes(extension) && !isPDFEncrypted) {
				await sendPhoto({ file, key, checkout_token: checkoutToken });
				setPhotoFile(file);
				setSubmissionMethod(PHOTO);
			} else {
				setError(
					isPDFEncrypted
						? `The uploaded PDF file appears to be password protected. Please upload a PDF without a password.`
						: // eslint-disable-next-line max-len
							`Invalid file type. Please upload a file with one of the following extensions: ${ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.join(
								', '
							)}.`
				);
				setSubmissionMethod(REMIND);
			}
		} catch (err) {
			console.error(err);
			showToast(TOAST.UPLOAD_RX_ERROR);
			setSubmissionMethod(REMIND);
		} finally {
			setIsUploadingRx(false);
		}
	};

	const handleFileDelete = () => {
		fileRef.current.value = null;
		setSubmissionMethod(REMIND);
		setPhotoFile(null);
	};

	useEffect(() => {
		updateBundleSubmissionMethod(extraProperties);

		if (submissionMethod !== PHOTO) {
			setPhotoFile(null);
			fileRef.current.value = null;
		}

		if (submissionMethod !== DOCTOR) {
			setExtraProperties({});
		}
	}, [submissionMethod]);

	return (
		<>
			<Flex data-testid='my-prescription' column gap={2} className={styles.container} fullWidth>
				<Flex align='center' justify='between' fullWidth>
					<Paragraph className={styles.bundleTitle}>My Prescription</Paragraph>
					<TypographyButton small>Required</TypographyButton>
				</Flex>
				<Flex column style={{ paddingTop: '1.6rem' }} gap={3}>
					<PrescriptionOption
						checked={submissionMethod === PHOTO}
						clickCallback={() => {
							!photoFile && fileRef.current && fileRef.current.click();
						}}
						inputCallback={e => {
							setError(null);
							handleFileChange(e, bundleKey);
						}}
						clearInput={handleFileDelete}
						key={'photo-option'}
						lozenge={{
							label:
								submissionMethod === PHOTO ? (
									<Flex gap={2} align='center'>
										<Flex className={styles.checkmark} align='center' justify='center'>
											<Checkmark color={variables.greenLight} thick width={6} height={5} />
										</Flex>
										Photo Uploaded!
									</Flex>
								) : (
									'Get your frames faster!'
								),
							backgroundColor: submissionMethod === PHOTO ? variables.greenLight : variables.blue1,
							color: submissionMethod === PHOTO ? variables.greenShade : variables.gray0,
						}}
						ref={fileRef}
						subcopy='A complete, uncropped picture of your valid prescription is perfect!'
						supplementalCopy={photoFile?.name}
						error={error}
						title='Upload a photo'
						method={PHOTO}
					/>
					{isCartPDTest && submissionMethod === PHOTO && (
						<PhotoPd submit={updatePDMeasurement} isPdNeeded={!hasMeasuredPd} />
					)}
					<PrescriptionOption
						checked={submissionMethod === REMIND}
						clickCallback={() => setSubmissionMethod(REMIND)}
						key={'remind-option'}
						subcopy='No problem. Place your order now and get reminded later.'
						title='Remind me later'
						method={REMIND}
					/>

					{['en-US', 'en-CA'].includes(locale) && !isNoDrTest && (
						<Modal open={modalOpen} onOpenChange={setModalOpen}>
							<Modal.Trigger tabIndex={-1} style={{ textAlign: 'left' }}>
								<PrescriptionOption
									checked={submissionMethod === DOCTOR}
									subcopy='Enter in your eye doctor&#39;s info, and we&#39;ll reach out to obtain your prescription.'
									title='Have us contact your doctor'
									method={DOCTOR}
								/>
							</Modal.Trigger>
							<Modal.Content center>
								<DoctorForm
									callback={async newProperties => {
										try {
											await sendDoctorInfo({
												...newProperties,
												...{ key: bundleKey ?? 'unknown', checkout_token: checkoutToken },
											});
											setSubmissionMethod(DOCTOR);
											setExtraProperties(newProperties);
										} catch (err) {
											console.error(err);
											showToast(TOAST.UPLOAD_RX_ERROR);
											setSubmissionMethod(REMIND);
										}
										setModalOpen(false);
									}}
								/>
							</Modal.Content>
						</Modal>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default PrescriptionOptions;
