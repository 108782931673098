import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Button, Flex, Heading, Input, Modal, Paragraph, Select } from '@components';
import { STATES_CA, STATES_US } from '@utils/states';
import { DoctorInfo } from '@ts/poms';
import styles from './DoctorForm.module.scss';

const DoctorForm = ({ callback }) => {
	const {
		register,
		handleSubmit,
		formState: { dirtyFields, errors },
		setValue,
		watch,
	} = useForm<DoctorInfo>();
	const doctor_state = watch('doctor_state');
	const { locale } = useRouter();

	const onSubmit = async data => {
		const parts = data.patient_dob.split('-');
		const day = parseInt(parts[2], 10);
		const month = parseInt(parts[1], 10);
		const year = parseInt(parts[0], 10);
		const parsedData = { ...data, patient_dob: `${month}/${day}/${year}` };
		callback(parsedData);
	};

	const STATES = () => {
		switch (locale) {
			case 'en-US':
				return STATES_US;
			case 'en-CA':
				return STATES_CA;
			default:
				return STATES_US;
		}
	};

	return (
		<form data-testid='call-doctor-form' className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h2'>Have us call your doctor</Heading>
			<Paragraph style={{ marginBottom: '2.4rem' }}>
				Enter in your eye doctor&#39;s information, and we&#39;ll reach out to obtain your prescription information
			</Paragraph>
			<Input
				{...register('patient_name', { required: "Patient's full name is required.", pattern: /\D/ })}
				errorMessage={errors?.patient_name?.message}
				placeholder='Patient&#39;s Full Name'
				type='text'
				required
			/>
			<Input
				{...register('patient_dob', {
					required: "Patient's birthdate is required.",
					pattern: /^\d{4}-\d{1,2}-\d{1,2}$/,
					validate: dob => {
						const parts = dob.split('-');
						const day = parseInt(parts[2], 10);
						const month = parseInt(parts[1], 10);
						const year = parseInt(parts[0], 10);

						if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

						const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

						if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

						return day > 0 && day <= monthLength[month - 1];
					},
				})}
				errorMessage={errors?.patient_dob?.message}
				placeholder='Patient&#39;s Birthdate'
				type='date'
				required
			/>
			<Input
				{...register('doctor_clinic_name', { required: 'Doctor or clinic name is required.', pattern: /\D/ })}
				errorMessage={errors?.doctor_clinic_name?.message}
				placeholder='Doctor or Clinic Name'
				type='text'
				required
			/>
			<Input
				{...register('doctor_phone_number', {
					required: true,
					pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
				})}
				errorMessage={errors?.doctor_phone_number ? 'Doctor or clinic phone is required.' : null}
				placeholder='Doctor or Clinic Phone'
				type='tel'
				mask='999-999-9999'
				required
			/>
			<Select
				{...register('doctor_state', { required: true })}
				placeholder='Doctor or Clinic State/Province'
				handler={e => {
					setValue('doctor_state', String(e));
				}}
				buttonProps={{ fullWidth: true, spaceBetween: true }}
				values={Object.entries(STATES()).map(([, val]) => val)}
				containerClassName='docForm'
				isListOnTop
			/>
			<Flex gap={3} justify='end'>
				<Modal.Close asChild>
					<Button label='Cancel' color='white' />
				</Modal.Close>
				<Button
					type='submit'
					label='Submit'
					disabled={
						!(
							dirtyFields.patient_name &&
							dirtyFields.patient_dob &&
							dirtyFields.doctor_clinic_name &&
							dirtyFields.doctor_phone_number &&
							doctor_state
						)
					}
				/>
			</Flex>
		</form>
	);
};

export default DoctorForm;
