import { useQuery } from '@tanstack/react-query';
import { productHandlesQuery, productQuery } from '@services/shopify/queries';
import { fetchStorefrontApi, cleanGraphqlResponse } from '@services/shopify';
import { normalizeCollectionProduct, normalizeProduct } from '@utils/normalizers/normalize-product';
import { NormalizedProduct, CleanedProduct, CleanedCollectionProduct, NormalizedVariant } from 'ts';
import { LOCALE_CODES, LOCALE_DICT } from '@constants';
import { paginateGraphql } from '..';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type UseProductQueryOptions = {
	/**
	 * @optional
	 * Allows you to set a query key for the React Query cache that differs from the default query key (handle).
	 * This can be useful when the slug is dynamic and you want to fetch the same cache data in multiple places.
	 */
	queryKey?: string;
	/**
	 * @optional
	 * Will refresh the query when the value changes.
	 */
	queryRefreshVars?: string[];
	/**
	 * @optional
	 * Will fetch the TOP FRAME product variant for the given frame.
	 */

	initialData?: NormalizedProduct | NormalizedVariant;
	/**
	 * @optional
	 * Provides callback for when the query is successful.
	 * Useful for re-setting default data in the parent component.
	 */
	onSuccess?: (data: NormalizedProduct | NormalizedVariant) => void;
	/**
	 * @optional
	 * Passes the enabled attribute to the React Query client.
	 */
	enabled?: boolean;
	locale?: string;
};

export type GetProductOptions = {
	includeDescription?: boolean;
	includeSpecificFrameVariant?: boolean;
	skipCollections?: boolean;
	skipImages?: boolean;
	skipVariants?: boolean;
	selectedOptions?: Array<{
		name: string;
		value: string;
	}>;
	country?: string;
	maxVariants?: number;
};

/**
 * @name useProductQuery
 *
 * If you are requesting a product with no changes, only submit the handle.
 * @example useProductQuery('product-handle')
 *
 * Options for the Shopify GraphQL API? Pass in a second argument or NULL:
 * @example useProductQuery('product-handle', {}, { frame: 'Larkin' })
 *
 * Options for the React Query Client? Pass in an object as the third argument or leave blank:
 * @example useProductQuery('product-handle', { queryKey: 'custom-query-key', queryRefreshVars: ['custom-query-var'] })
 *
 */
export const useProductQuery = (
	handle: string,
	getProductOptions: GetProductOptions = {},
	{
		queryKey = undefined,
		queryRefreshVars = [],
		initialData = null,
		onSuccess = undefined,
		enabled = true,
		locale = LOCALE_CODES.US,
	}: UseProductQueryOptions = {}
) => {
	return useQuery({
		queryKey: [queryKey ?? handle, ...queryRefreshVars],
		queryFn: async () => {
			let product;
			if ('selectedOptions' in getProductOptions) {
				const { selectedOptions, skipVariants = true, includeSpecificFrameVariant, country } = getProductOptions;
				product = await getProduct(handle, { selectedOptions, skipVariants, includeSpecificFrameVariant, country });
			} else {
				if (handle === 'pair-care') {
					product = await getProduct(handle, { country: locale });
				} else {
					product = await getProduct(handle, getProductOptions);
				}
			}

			if (onSuccess) onSuccess(product);
			return product;
		},
		retry: 3,
		// TODO: allow for config via hook args
		staleTime: Infinity,
		cacheTime: 5 * 60 * 1000,
		notifyOnChangeProps: ['data', 'error', 'status'],
		refetchOnWindowFocus: false,
		enabled,
		...(initialData ? { initialData } : {}),
	});
};

/**
 * General fetching utility
 *
 * If you require adding options for the Shopify GraphQL API, you can pass in an object as the second argument:
 * @example useProductQuery('product-handle', { frame: 'Larkin' })
 *
 * @param {string} handle
 * @return {object} Normalized product info from Shopify's GraphQL Storefront API
 * */
export async function getProduct(
	handle: string,
	getProductOptions: GetProductOptions = {}
): Promise<NormalizedProduct | NormalizedVariant> {
	const {
		includeDescription = true,
		skipCollections = false,
		skipImages = false,
		skipVariants = false,
		selectedOptions = [{ name: 'Frame', value: 'Larkin' }],
		includeSpecificFrameVariant = false,
		country,
		maxVariants = 25,
	} = getProductOptions;
	const isById = handle.includes('gid://shopify/Product/');
	const countryCode = handle === 'pair-care' ? LOCALE_DICT[country].countryCode : country;
	const { product } = await fetchStorefrontApi(
		productQuery,
		{
			variables: {
				skipCollections,
				skipImages,
				skipVariants,
				includeDescription,
				includeSpecificFrameVariant,
				...(selectedOptions ? { selectedOptions } : {}),
				...(isById ? { id: handle } : { handle }),
				country: countryCode,
				maxVariants,
			},
		},
		{ redirect: 'follow' }
	);
	const cleaned = cleanGraphqlResponse<CleanedCollectionProduct | CleanedProduct>(product);

	if (includeSpecificFrameVariant) return normalizeCollectionProduct(cleaned as CleanedCollectionProduct) as NormalizedVariant;

	return normalizeProduct(cleaned as CleanedProduct) as NormalizedProduct;
}

export async function getAllProductHandles() {
	const dataToClean = await paginateGraphql({
		fetcher: fetchStorefrontApi,
		resourceName: 'products',
		query: productHandlesQuery,
		variables: null,
	});
	const cleaned = cleanGraphqlResponse(dataToClean);
	return cleaned;
}

/**
 * General fetching utility for multiple products
 *
 * @param {string[]} handles
 * @return {object[]} Normalized product info from Shopify's GraphQL Storefront API
 */
export async function getProducts(
	handles: string[],
	getProductOptions: GetProductOptions = {}
): Promise<(NormalizedProduct | NormalizedVariant)[]> {
	const products = await Promise.all(handles.map(async handle => getProduct(handle, getProductOptions)));
	return products;
}
