import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Logout = () => {
	return (
		<AccessibleIcon.Root label='logout'>
			<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M12 5L15.5 8.25L12 11.5' stroke='#080593' strokeWidth='1.5' strokeLinecap='square' />
				<path d='M14.25 8.25H7' stroke='#080593' strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path
					d='M11.5 1H3C1.89543 1 1 1.89543 1 3V13.5C1 14.6046 1.89543 15.5 3 15.5H11.5'
					stroke='#080593'
					strokeWidth='1.5'
					strokeLinecap='square'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Logout;
