import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type AccountIconProps = {
	label: string;
	loggedIn?: boolean;
};

const AccountIcon = ({ label, loggedIn }: AccountIconProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='9' cy='4' r='3.25' fill={loggedIn ? 'black' : 'white'} stroke='black' strokeWidth='1.5' />
				<path
					d='M0.783632 16.25C1.16255 12.0451 4.69647 8.75 9 8.75C13.3035 8.75 16.8374 12.0451 17.2164 16.25H0.783632Z'
					fill={loggedIn ? 'black' : 'white'}
					stroke='black'
					strokeWidth='1.5'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default AccountIcon;
