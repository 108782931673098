import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const LogoColor = ({ label }) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_105_460)'>
					<path
						d='M42.2157 20.5321C42.6298 23.0583 42.905 25.6051 43.248 28.1661C43.248 28.274 43.3357 28.4662 43.1674 28.4655C34.9754 29.3729 33.8349 29.7142 25.7594 30.7032C26.3091 36.2672 30.1024 50.1207 28.4512 50.3C18.496 51.381 8.89152 53.6148 3.12384 53.9991C3.01696 54.0043 2.98816 53.9785 2.97152 53.8661C2.94592 53.3353 2.92288 52.8012 2.90752 52.2672C2.73024 48.9666 1.19424 38.4689 0.896 32.3028C0.8608 32.0091 1.33952 32.3381 1.24352 31.8786C0.88 25.8281 0.31168 19.795 0 13.7419C13.2077 12.0749 26.663 10.5698 40.0269 9.99976C40.1587 10.003 40.1837 10.0312 40.2029 10.1354C40.4403 11.3075 40.6509 12.4855 40.9069 13.6538C40.9222 13.7239 41.0643 14.1757 41.0675 14.2039C41.1987 15.425 41.6691 16.7745 41.8477 17.9898C41.92 18.6112 42.1613 20.4363 42.2157 20.5321Z'
						fill='#AAD6EF'
					/>
					<path
						d='M55.0611 18.4351C39.872 18.5733 24.2918 17.1389 9.16224 16.1248C8.36992 23.8269 8.07936 33.2995 7.488 41.1173C7.47968 41.2645 12.2432 41.4913 12.2739 41.6378C21.1251 41.5691 30.016 42.4797 38.784 43.8068C43.4406 44.0298 52.8774 44.2759 57.5546 44.3157L60.5254 44.4488C61.9219 44.3511 61.9891 44.4758 63.0854 44.4713C63.1418 44.4713 63.1718 44.4417 63.1789 44.3761C63.2493 43.6647 63.3069 42.952 63.3619 42.2394C63.6358 34.6902 64.4442 27.0678 63.6986 19.5334C63.6774 19.4402 63.6915 19.2873 63.5853 19.259C60.7597 18.752 57.9315 18.4011 55.0611 18.4351Z'
						fill='#00AE4F'
					/>
					<path
						d='M22.5735 27.1391C21.1303 27.1391 19.938 27.7689 19.3312 28.8196H19.1226V27.1391C17.9776 27.5029 16.6733 27.5626 15.4503 27.5658V43.639H19.3312V37.095H19.5405C20.1684 37.8302 21.298 38.271 22.5108 38.271C25.3978 38.271 27.4893 35.9395 27.4893 32.7051C27.4893 29.4706 25.3978 27.1391 22.5735 27.1391ZM21.0887 37.4311C19.8336 37.4311 19.3312 36.1921 19.3312 34.2821V31.4448C19.3312 29.3652 19.8548 28.0844 21.1303 28.0844C22.72 28.0844 23.3063 29.9745 23.3063 32.7051C23.3063 35.4357 22.6996 37.4311 21.0887 37.4311Z'
						fill='black'
					/>
					<path
						d='M46.0595 27.1391C44.8646 27.5189 43.4137 27.5684 42.0851 27.5671V38.0615H46.0595V27.1391Z'
						fill='black'
					/>
					<path
						d='M52.1735 31.5502C52.1735 31.0252 52.4871 30.7097 53.01 30.7097H56.5658L56.9844 27.1391L55.3108 27.2439C53.7421 27.3493 52.5498 28.5883 52.1748 30.4995H51.9655V27.1391C50.8199 27.508 49.5105 27.5645 48.2004 27.5665V38.0615H52.1773L52.1735 31.5502Z'
						fill='black'
					/>
					<path
						d='M34.5683 27.1391C31.0541 27.1391 29.2973 28.3364 29.088 31.067L29.0349 31.7809L33.1251 30.9751V30.5001C33.1251 28.337 33.5021 27.56 34.5683 27.56C35.447 27.56 36.0537 28.2528 36.0537 30.0805V32.1807L32.9369 32.7494C29.8009 33.3169 28.5209 34.3669 28.5209 36.0269C28.5209 37.3925 29.8592 38.2762 31.9725 38.2762C34.0429 38.2762 35.4867 37.5204 36.0512 36.176H36.2605V38.0667H39.943V30.8934C39.9456 28.2425 37.9213 27.1391 34.5683 27.1391ZM36.0537 33.2725C36.0537 35.8135 35.3427 37.2209 34.0454 37.2209C33.2294 37.2209 32.7065 36.5282 32.7065 35.4569C32.7065 34.3001 33.6902 32.9994 36.0537 32.6003V33.2725Z'
						fill='black'
					/>
					<path
						d='M46.0429 21.6522C46.0429 21.6522 44.6246 21.8527 42.0186 22.14V25.6495C44.4346 25.6874 46.0429 25.1617 46.0429 25.1617V21.6522Z'
						fill='black'
					/>
				</g>
				<defs>
					<clipPath id='clip0_105_460'>
						<rect width='64' height='44' fill='white' transform='translate(0 9.99976)' />
					</clipPath>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default LogoColor;
