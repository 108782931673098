const getAssetExtensionFromUrl = url => {
	return url.match(/\.(jpg|png|gif|webp|jpeg|svg|mp4|m3u8|mov)/gim);
};

export const isVideoUrl = assetUrl => {
	const videoExtensions = [
		'mp4',
		'mov',
		'avi',
		'wmv',
		'flv',
		'3gp',
		'webm',
		'mkv',
		'm4v',
		'mpg',
		'mpeg',
		'ogg',
		'ogv',
		'm2v',
		'm3u8',
	];
	if (!assetUrl) {
		return null;
	}
	const fileExtension = getAssetExtensionFromUrl(assetUrl);
	return fileExtension?.[0] && videoExtensions.includes(fileExtension[0].replace('.', ''));
};

export const getQueryParamValue = (param: string, url: string, useFakeDomain = true) => {
	const parsedUrl = new URL(url, useFakeDomain ? 'https://fake.com' : undefined);
	const searchParams = parsedUrl.searchParams;
	const queryParamValue = searchParams.get(param);

	return queryParamValue;
};

export const buildUrl = (path: string, searchParams: Record<string, string>) => {
	const url = new URL(path, location.origin);
	if (searchParams) {
		Object.keys(searchParams).forEach(key => {
			if (searchParams[key]) url.searchParams.set(key, searchParams[key]);
		});
	}
	return url;
};
