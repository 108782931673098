import { FC, forwardRef } from 'react';
import { TypographyProps } from '@ts/components';
import styles from './NavItemTypography.module.scss';

/**
 * Navigation Title
 *
 * @default Mobile
 * - Size: 1.8rem
 * - Weight: 500
 * - Height: 2.7rem
 * - Family: var(--poppins)
 *
 * @default Desktop
 * - Size: 1.6rem
 * - Weight: 500
 * - Height: 1.9rem
 * - Family: var(--platform)
 */
const NavItemTypography: FC<TypographyProps<HTMLParagraphElement>> = forwardRef(({ children, style, ...props }, forwardedRef) => {
	return (
		<p
			className={styles['default']}
			style={{
				...style,
			}}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</p>
	);
});

NavItemTypography.displayName = 'NavItem';

export default NavItemTypography;
