import { createContext, useContext, useState, useRef } from 'react';
import { ToastProvider as ReactToastProvider } from '@radix-ui/react-toast';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
	const [toast, setToast] = useState(null);
	const [toastProps, setToastProps] = useState(null);
	const timeoutRef = useRef(null);

	function showToast(newToast, props) {
		setToastProps(props);
		setToast(newToast);

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
			setToastProps(null);
		}

		if (newToast?.duration) {
			timeoutRef.current = setTimeout(() => {
				setToast(null);
				setToastProps(null);
			}, newToast.duration);
		}
	}

	return (
		<ToastContext.Provider
			value={{
				toast,
				toastProps,
				setToast,
				showToast,
			}}
		>
			<ReactToastProvider duration={5000}>{children}</ReactToastProvider>
		</ToastContext.Provider>
	);
};

export function useToastContext() {
	return useContext(ToastContext);
}
