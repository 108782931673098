/* eslint-disable max-lines */
import { NormalizedCart } from '@ts/cart';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { LOCALE_DICT, getShopifyIdFromGID } from '@utils/index';
import { DataLayerImpression, DataLayerProduct, pushToDataLayer, pushUserData, GTMDataLayerProduct } from './data-layer';
import {
	getUserProperties,
	getImpressionsFromCart,
	getImpressionsFromHits,
	getImpressionsFromVariants,
	getImpressionsFromProducts,
	getVariants,
	getVariant,
} from './helpers/elevar';

const trackPageView = async (url: string) => {
	window.ElevarInvalidateContext?.();
	// For product pages, we don't want to push user data
	// because it will be pushed on the product page
	// by trackProductListView, trackVariantListView and trackProductView.
	const productPages = /\/(men|women|top-frames\/[a-zA-Z0-9-%\/?=]+)/g;
	const isProductPage = productPages.test(url);

	if (!isProductPage) {
		await pushUserData();
	}
};

/** Customer Logs into their account **/
const trackLogin = async () => {
	window.ElevarDataLayer = window?.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_login',
		user_properties: await getUserProperties(),
	});
};

/** Customer creates new account **/
const trackRegister = async data => {
	window.ElevarDataLayer = window?.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_sign_up',
		user_properties: {
			user_consent: '',
			visitor_type: 'logged_in',
			customer_address_1: '',
			customer_address_2: '',
			customer_city: '',
			customer_country: '',
			customer_email: data.email,
			customer_first_name: data.firstName,
			customer_id: '',
			customer_last_name: data.lastName,
			customer_order_count: '',
			customer_phone: '',
			customer_province: '',
			customer_province_code: '',
			customer_tags: '',
			customer_total_spent: '0.00',
			customer_zip: '',
		},
	});
};

/** Customer signs up for newsletter **/
const trackNewsletterSignup = async email => {
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;
	pushToDataLayer({
		event: 'dl_newsletter_signup',
		ecommerce: {
			locale: locale,
		},
		user_properties: {
			customer_email: email,
			user_consent: true,
			visitor_type: 'guest',
		},
	});
};

/** Collection page product impressions **/
const trackProductListView = async ({ products, path }: { products: NormalizedProduct[]; path: string }) => {
	const impressions = getImpressionsFromProducts({ products, path });

	await pushUserData();
	window.ElevarDataLayer = window?.ElevarDataLayer ?? [];

	window?.ElevarDataLayer.push({
		event: 'dl_view_item_list',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: products[0].price?.currencyCode || 'USD',
			impressions: impressions,
		},
	});
};

/** Collection top-frame pages impressions **/
const trackVariantListView = async ({ variants, path }: { variants: NormalizedVariant[]; path: string }) => {
	const impressions = getImpressionsFromVariants({ variants, path });

	await pushUserData();
	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_view_item_list',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: variants[0]?.price?.currencyCode || 'USD',
			impressions: impressions,
		},
	});
};

/** Product detail view // note the list object which carries from collection page
When user selects variant this will push an additional event with revised product data
**/
const trackProductView = async ({ variant, path }: { variant: NormalizedVariant; path: string }) => {
	const product: DataLayerProduct = getVariant({ variant, path });
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;

	pushToDataLayer({
		event: 'enhanceEcom productView',
		ecommerce: {
			currencyCode: variant.price?.currencyCode || 'USD',
			locale,
			detail: {
				products: [{ ...product, image_url: product.image, id: product.product_id }],
			},
		},
	});

	await pushUserData();
	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_view_item',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: variant.price?.currencyCode || 'USD',
			locale,
			detail: {
				actionField: {
					list: path,
					action: 'detail',
				},
				products: [product],
			},
		},
	});
};

/** Collection/Search page product click...
this is the product the user clicks on from collection page **/
const trackSelectedProduct = async ({ variant, path }: { variant: NormalizedVariant; path: string }) => {
	const product: DataLayerProduct = getVariant({ variant, path });

	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_select_item',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: variant.price?.currencyCode || 'USD',
			click: {
				actionField: {
					list: path,
					action: 'click',
				},
				products: [product],
			},
		},
	});
};

/** Search page product impressions  **/
const trackSearch = async hits => {
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;
	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_view_search_results',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: LOCALE_DICT[locale]?.currencyCode || 'USD',
			actionField: {
				list: 'search results',
			},
			impressions: getImpressionsFromHits(hits),
		},
	});
};

/** Add to Cart // note the list object which carries from collection page **/
const trackAddToCart = async ({ variants, path }: { variants: NormalizedVariant[]; path: string }) => {
	const parsedVariants: DataLayerProduct[] = getVariants({ variants, path });
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;
	const user_properties = await getUserProperties();

	pushToDataLayer({
		event: 'enhanceEcom addToCart',
		user_properties,
		ecommerce: {
			currencyCode: variants[0]?.price?.currencyCode || 'USD',
			locale,
			add: {
				actionField: {
					list: path,
				},
				products: parsedVariants,
			},
		},
	});

	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_add_to_cart',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: variants[0]?.price?.currencyCode || 'USD',
			locale,
			add: {
				actionField: {
					list: path,
				},
				products: parsedVariants,
			},
		},
	});
};

/** Remove from Cart // note the list object which carries from collection page **/
const trackRemoveFromCart = async ({ variant, path }: { variant: NormalizedVariant; path: string }) => {
	const product: DataLayerProduct = getVariant({ variant, path });
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;

	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_remove_from_cart',
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: variant.price?.currencyCode || 'USD',
			locale,
			remove: {
				actionField: {
					list: path,
				},
				products: [product], // See the products array below
			},
		},
	});
};

/** View Cart/Mini Cart **/
const trackCartView = async (cart: NormalizedCart) => {
	if (!cart) return;
	const impressions: DataLayerImpression[] = getImpressionsFromCart(cart);
	const locale = typeof window === 'undefined' ? 'en-US' : window.locale;

	// Remove this once we turn on Elevar
	const trackedData: GTMDataLayerProduct[] = cart.lines.map(({ variant, quantity }) => ({
		name: variant.name,
		id: getShopifyIdFromGID(variant.id),
		price: variant.price.amount * quantity,
		category: variant.product.type,
		variant: variant.option,
		quantity,
		image_url: variant.image.url,
	}));

	pushToDataLayer({
		event: 'viewCart',
		ecommerce: {
			locale,
			currencyCode: LOCALE_DICT[locale]?.currencyCode || 'USD',
			view: {
				products: trackedData,
			},
		},
	});

	window.ElevarDataLayer = window.ElevarDataLayer ?? [];
	window?.ElevarDataLayer.push({
		event: 'dl_view_cart',
		cart_total: `${cart.subtotal?.toFixed(2) ?? '0.00'}`,
		user_properties: await getUserProperties(),
		ecommerce: {
			currencyCode: LOCALE_DICT[locale]?.currencyCode || 'USD',
			actionField: {
				list: 'Shopping Cart',
			},
			impressions: impressions,
		},
	});
};

export {
	trackAddToCart,
	trackCartView,
	trackLogin,
	trackNewsletterSignup,
	trackRegister,
	trackRemoveFromCart,
	trackPageView,
	trackSelectedProduct,
	trackProductView,
	trackProductListView,
	trackVariantListView,
	trackSearch,
};
