import Link from 'next/link';
import { LogoColor, MobileNavClose, MobileNavOpen, PairLogo } from '@components';
import styles from './Logo.module.scss';

const Logo = ({ isMobileNavOpen, setMobileNavOpen }) => {
	const rootUrl = '/';

	return (
		<>
			<button
				type='button'
				className={styles.mobileNavButton}
				id='navbutton'
				aria-haspopup='true'
				aria-controls='navlist'
				data-is-mobile-nav-open={isMobileNavOpen}
				onClick={() => {
					setMobileNavOpen(!isMobileNavOpen);
				}}
			>
				<MobileNavOpen label='mobile-nav-open' />
				<MobileNavClose label='mobile-nav-close' />
				<span className='icon__fallback-text'>Open/Close</span>
			</button>

			<div className={styles.logo}>
				<div itemScope itemType='http://schema.org/Organization' data-testid='logo'>
					<Link href={rootUrl} itemProp='url'>
						<PairLogo />
						<LogoColor label='logo-pair-eyewear-color' />
					</Link>
				</div>
			</div>
		</>
	);
};

export default Logo;
