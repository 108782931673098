import { createElement, FC, forwardRef, ReactNode } from 'react';
import Balancer from 'react-wrap-balancer';
import cn from 'classnames';
import styles from './Heading.module.scss';

type Tag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = {
	children?: ReactNode;
	className?: string;
	style?: Partial<CSSStyleDeclaration>;
	tag?: Tag;
	ref?: React.ForwardedRef<HTMLHeadingElement>;
	withBalancer?: boolean;
	removeDefaultMargin?: boolean;
	fixedSize?: boolean;
};

/**
 * Heading
 *
 * @tagOptions [h1, h2, h3, h4, h5, h6]
 * @default
 * - Size: 4rem
 * - Weight: 500
 * - Height: 4.8rem
 * - Family: var(--platform)
 * - Margin: 0
 */
const Heading: FC<Props> = forwardRef(
	(
		{
			children,
			className,
			style,
			tag = 'h1',
			withBalancer = false,
			removeDefaultMargin = false,
			fixedSize = false,
			...props
		},
		forwardedRef
	) => {
		const TAG = tag;

		props.ref = forwardedRef;

		const classes = cn(styles.default, styles[tag], className, {
			[styles['defaultMargin']]: !removeDefaultMargin,
			[styles[`${tag}--noClamp`]]: fixedSize,
		});

		const newProps = {
			...props,
			className: classes,
			style,
		};

		const textContent = withBalancer ? <Balancer>{children}</Balancer> : children;

		return createElement(TAG, newProps, textContent);
	}
);

Heading.displayName = 'Heading';

export default Heading;
