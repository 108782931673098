import { FC, forwardRef } from 'react';
import { TypographyProps } from '@ts/components';
import styles from './Paragraph.module.scss';

/**
 * Paragraph
 *
 * @default
 * - Size: 1.4rem
 * - Weight: 400
 * - Height: 150%
 * - Family: var(--poppins)
 */
const Paragraph: FC<TypographyProps<HTMLParagraphElement>> = forwardRef(
	({ children, className, large = false, small = false, ...rest }, forwardedRef) => {
		return (
			<p
				ref={forwardedRef}
				className={`${styles.default} ${large && styles.large} ${small && styles.small} ${className}`}
				{...rest}
			>
				{children}
			</p>
		);
	}
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
