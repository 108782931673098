import Cookies, { CookieAttributes } from 'js-cookie';
import { SHOPIFY_CART_ID_COOKIE, SHOPIFY_COOKIE_EXPIRE } from '@constants';

const getCartId = () => Cookies.get(SHOPIFY_CART_ID_COOKIE);

const setCartId = (id: string | null, options?: CookieAttributes) => {
	if (!id) {
		Cookies.remove(SHOPIFY_CART_ID_COOKIE);
	} else {
		Cookies.set(
			SHOPIFY_CART_ID_COOKIE,
			id,
			options ?? {
				expires: SHOPIFY_COOKIE_EXPIRE,
			}
		);
	}
};

export { getCartId, setCartId };
