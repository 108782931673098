import algoliasearch from 'algoliasearch';

export const algoliaClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
);
const searchClient: typeof algoliaClient = {
	...algoliaClient,
	search(requests) {
		const newRequests = requests.map(request => {
			// Test for empty string and change `analytics` request parameter to `false`
			if (!request.params.query || request.params.query.length === 0) {
				return {
					...request,
					params: {
						...request.params,
						analytics: false,
					},
				};
			}
			return request;
		});

		if (newRequests.every(({ params }) => !params.query)) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					page: 0,
					processingTimeMS: 0,
					hitsPerPage: 0,
					exhaustiveNbHits: false,
					query: '',
					params: '',
				})),
			});
		}
		return algoliaClient.search(newRequests);
	},
};

export default searchClient;
