import { Portal, Content, DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import { AnimatePresence, m } from 'framer-motion';
import { fade } from '@utils/motions';

type DropdownMenuContentCustomProps = DropdownMenuContentProps & {
	children: React.ReactNode;
	props?: null;
	forwardedRef?: null;
};

export const DropdownMenuContent = ({ children, ...props }: DropdownMenuContentCustomProps) => {
	return (
		<AnimatePresence>
			<Portal>
				<Content {...props}>
					<m.div {...fade}>{children}</m.div>
				</Content>
			</Portal>
		</AnimatePresence>
	);
};

DropdownMenuContent.displayName = DropdownMenuContent;

export default DropdownMenuContent;
