import { useCallback } from 'react';
import {
	LENSES,
	PREMIUM_ULTRA_THIN_COPY,
	PRODUCT_TYPES,
	IMAGE_PLACEHOLDER,
	EMPTY_PRODUCT,
	EMPTY_VARIANT,
	LOCALE_DICT,
} from '@constants';
import { Card } from '@components';
import { useIsMobile } from '@utils/hooks';
import { generateVariantCaption } from '@utils/shopify';
import { CartMutationLineItem, NormalizedCartLine } from '@ts/cart';
import { CardProps, LineItemCardAdditionalInfo, LineItemCardProps } from '@ts/components';
import { LineItemPOMS } from '@ts/poms/orders';
import { NormalizedProduct } from '@ts/product';
import { objectToArray } from '@utils/objects';
import { useCartContext } from '@context';

type ProductType = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];

export function getNormalizedProduct({
	data,
	dataSource,
}: {
	data: NormalizedCartLine | LineItemPOMS;
	dataSource: 'shopify' | 'poms';
}): { product: NormalizedProduct; additionalInfo: LineItemCardAdditionalInfo } {
	const cartLine = data as NormalizedCartLine;
	const orderLine = data as LineItemPOMS;

	switch (dataSource) {
		case 'poms':
			const {
				id: orderLineId,
				price,
				price_set,
				product_title,
				product_type,
				image,
				variant_title,
				removed,
				quantity,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}: any = orderLine;
			const variantFromPoms = {
				...EMPTY_VARIANT,

				name: variant_title,
				availableForSale: true,
				image: {
					url: image?.src ?? IMAGE_PLACEHOLDER,
				},
				price: {
					amount: price_set?.presentment_money?.amount ?? Number(price),
					currencyCode: price_set?.presentment_money?.currency_code ?? LOCALE_DICT['en-US'].currencyCode,
				},
				product: {
					id: `${orderLineId}`,
					name: product_title,
					handle: product_title,
					type: product_type as ProductType,
					tags: [],
				},
				option: variant_title,
			};

			return {
				product: {
					...EMPTY_PRODUCT,
					id: `${orderLineId}`,
					handle: product_title,
					name: product_title,
					type: product_type as ProductType,
					variants: [variantFromPoms],
				},
				additionalInfo: {
					cartLineId: null,
					// Typically we only want to display the "color" part of the variant title
					// When displaying POMS data, we want the caption to match what customers see at checkout
					// So we display the full "variant_title" here
					description: product_type.includes(PRODUCT_TYPES.BASE_FRAME)
						? `in ${variant_title}`
						: generateVariantCaption(variantFromPoms, product_type as ProductType),
					showTooltip: false,
					properties: null,
					optimistic: false,
					quantity,
					removed,
				},
			};
		case 'shopify':
			const {
				id: cartLineId,
				variant,
				properties,
				quantity: cartLineQuantity,
				optimistic = false,
				sellingPlanAllocation = null,
			} = cartLine;
			return {
				product: {
					...EMPTY_PRODUCT,
					...{
						id: variant?.product?.id,
						title: variant?.product?.name,
						handle: variant?.product?.handle,
						name: variant?.product?.name,
						productType: variant?.product?.type as ProductType,
						tags: variant?.product?.tags,
						variants: [variant],
						sellingPlanAllocation: sellingPlanAllocation,
					},
				},
				additionalInfo: {
					cartLineId: cartLineId,
					description: null,
					showTooltip: variant?.product.handle === LENSES.PREMIUM_PLUS,
					removed: false,
					quantity: cartLineQuantity,
					optimistic,
					properties,
				},
			};
	}
}

const LineItemCard = ({
	data,
	dataSource,
	textOnly = false,
	noInteraction = false,
	deletionCallback = null,
	canRemoveSelfFromCart = true,
	baseFrame = null,
	optimistic: bundleIsOptimistic,
	tooltipMessage,
	dataTags = {
		button: {},
		zoom: {},
	},
}: LineItemCardProps) => {
	const { isCartMutating, handleCartRemove, handleCartUpdate } = useCartContext();
	const isMobile = useIsMobile();
	const { product, additionalInfo } = getNormalizedProduct({ data, dataSource });
	const variant = product?.variants?.[0];
	const parentCollectionHandle = additionalInfo?.properties?._collectionPath?.split('/')[2] ?? null;

	const onDeleteCallback = deletionCallback
		? () => {
				deletionCallback();
			}
		: () => {
				if (data.quantity > 1) {
					const customAttributes = objectToArray(additionalInfo.properties);
					handleCartUpdate([
						{
							...data,
							quantity: data.quantity - 1,
							customAttributes: customAttributes,
						} as CartMutationLineItem,
					]);
				} else {
					handleCartRemove([additionalInfo?.cartLineId as string], variant, additionalInfo.properties?._collectionPath);
				}
			};

	const getSecondaryAction = useCallback(() => {
		if (!baseFrame && additionalInfo.showTooltip) {
			return 'tooltip';
		}
		if (variant && variant?.product?.type.includes(PRODUCT_TYPES.TOP_FRAME)) {
			return 'zoom';
		}
	}, [baseFrame, additionalInfo.showTooltip, variant]);

	const cardProps: Omit<CardProps, 'children'> = {
		product: product,
		variant: variant,
		containerType: 'line-item',
		contentType: 'micro',
		description: additionalInfo?.description,
		showDescription: !textOnly || !!baseFrame,
		buttonGroupType: 'micro',
		primaryAction: !baseFrame && canRemoveSelfFromCart && !noInteraction ? 'delete' : 'none',
		primaryActionData: {
			callback: data.id ? onDeleteCallback : () => false,
			disabled: bundleIsOptimistic || isCartMutating,
			optimistic: additionalInfo?.optimistic,
		},
		secondaryAction: noInteraction ? 'none' : getSecondaryAction(),
		secondaryActionData: {
			message: tooltipMessage ?? PREMIUM_ULTRA_THIN_COPY,
			frameShape: additionalInfo?.properties?._Frame,
		},
		compact: true,
		noBorder: textOnly,
		hoverable: !textOnly && !noInteraction,
		noImage: textOnly,
		productRemoved: additionalInfo?.removed,
		quantity: additionalInfo?.quantity,
		dataTags: { ...dataTags, favorite: {} },
		isMobile,
		parentCollectionHandle,
	};

	return variant || textOnly || noInteraction ? <Card {...cardProps} /> : null;
};

export default LineItemCard;
