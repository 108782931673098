import { useRef } from 'react';
import dynamic from 'next/dynamic';
import { useCartContext } from '@context';
import { AccountButton, CartNavButton, FavoriteButton, MinicartDrawer, SearchDrawer } from '@components';
import { useCustomer } from '@services/shopify/hooks';
import styles from './UserActionNav.module.scss';

const Microcart = dynamic(() => import('@components').then(mod => mod.Microcart), {
	loading: () => <CartNavButton href='/cart' />,
});

const UserActionNav = () => {
	const Customer = useCustomer();
	const openMinicartRef = useRef<HTMLDivElement>(null);
	const { isMicrocartTest } = useCartContext();

	return (
		<div className={styles.userActionNav}>
			<FavoriteButton customer={Customer.data} />

			<AccountButton customer={Customer.data} />

			<SearchDrawer openMinicartRef={openMinicartRef} />

			{isMicrocartTest ? <Microcart /> : <MinicartDrawer openMinicartRef={openMinicartRef} />}
		</div>
	);
};

export default UserActionNav;
