import { SVGAttributes } from 'react';
import variables from '@styles/export.module.scss';

const { gray3 } = variables;

type DivergentArrowsIconProps = SVGAttributes<SVGElement> & {
	width?: number | string;
	height?: number | string;
	className?: string;
	color?: string;
	onClick?: () => null;
};

const DivergentArrowsIcon = ({
	onClick,
	width = 35,
	height = 35,
	className,
	color = gray3,
	...rest
}: DivergentArrowsIconProps) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...rest}
		>
			<path d='M0 4C0 1.79086 1.79086 0 4 0H20V20H0V4Z' fill='none' />
			<path
				d='M8.5 6H9V5H8.5V6ZM5 8.5V9H6V8.5H5ZM8.5 5H5.7V6H8.5V5ZM5 5.7V8.5H6V5.7H5ZM5.7 5C5.3134 5 5 5.3134 5 5.7H6C6 5.86569 5.86569 6 5.7 6V5Z'
				fill={color}
			/>
			<path
				d='M8.64645 9.3538L9 9.70735L9.70711 9.00024L9.35355 8.64669L8.64645 9.3538ZM6.35355 5.64669L6 5.29314L5.29289 6.00024L5.64645 6.3538L6.35355 5.64669ZM9.35355 8.64669L6.35355 5.64669L5.64645 6.3538L8.64645 9.3538L9.35355 8.64669Z'
				fill={color}
			/>
			<path
				d='M11.5 14H11V15H11.5V14ZM15 11.5V11H14V11.5H15ZM11.5 15H14.3V14H11.5V15ZM15 14.3V11.5H14V14.3H15ZM14.3 15C14.6866 15 15 14.6866 15 14.3H14C14 14.1343 14.1343 14 14.3 14V15Z'
				fill={color}
			/>
			<path
				d='M11.3536 10.6464L11 10.2929L10.2929 11L10.6464 11.3536L11.3536 10.6464ZM13.6464 14.3536L14 14.7071L14.7071 14L14.3536 13.6464L13.6464 14.3536ZM10.6464 11.3536L13.6464 14.3536L14.3536 13.6464L11.3536 10.6464L10.6464 11.3536Z'
				fill={color}
			/>
		</svg>
	);
};

export default DivergentArrowsIcon;
