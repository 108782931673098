import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTailoredExperience } from '@services/contentful';
import { DemoListFields } from '@ts/contentful';

const useTailoredExperience = (): UseQueryResult<DemoListFields> => {
	return useQuery<DemoListFields>(['tailoredExperience'], async () => {
		return await getTailoredExperience();
	});
};

export default useTailoredExperience;
